/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react'
import { Box, ImageList, Typography } from '@mui/material'
import { Button } from '@mui/material'
import ActiveCaseCard from './ActiveCaseCard'
import { activeCaseLoader } from './ActiveCaseLoader'
import { affiliatedCompanyLoader } from './AffiliatedCompanyLoader'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import SortIcon from '@mui/icons-material/Sort'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
// import BusinessIcon from '@mui/icons-material/Business'
import AddNewCaseButton from './AddNewCaseButton'
import CaseFilters from './CaseFilters'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

// active case view component
function ActiveCaseList({
  sdk,
  setCurrentView,
  setIsLoading,
  filePath,
  setFilePath,
  setButtonState,
  setIsRenderingThreeDisabled,
  setDemo,
  userInfo,
  setIndication,
  setBackInfo,
  backInfo
}) {
  const [cardList, setCardList] = useState([]) // case card list to show active case view
  const [searchQuery, setSearchQuery] = useState('')

  const [isDemoChecked, setIsDemoChecked] = useState(true)
  const [genderFilter, setGenderFilter] = useState({ male: true, female: true, other: true })
  const [indicationFilter, setIndicationFilter] = useState({
    Face: true,
    Clavicle: true,
    Wrist: true,
    Calcaneus: true,
    Others: true
  })

  const [statusFilter, setStatusFilter] = useState({ complete: true, notComplete: true })

  const filterButtonRef = useRef(null)
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false)
  const [affiliatedCompany, setAffiliatedCompany] = useState('')
  const handleFilterPopoverToggle = () => {
    setIsFilterPopoverOpen(!isFilterPopoverOpen)
  }
  const [sortAnchorEl, setSortAnchorEl] = useState(null)
  const [sortOption, setSortOption] = useState('name')
  const [sortDirection, setSortDirection] = useState('asc')
  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget)
  }

  const handleSortClose = () => {
    setSortAnchorEl(null)
  }

  const handleSortOptionSelect = (option) => {
    if (sortOption !== option) {
      setSortDirection('asc')
    } else {
      setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'))
    }
    setSortOption(option)
    handleSortClose()
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userInfo === false) return

        // load cases from user
        // load cases from user
        const userCases = await activeCaseLoader(sdk, userInfo['id'], 100, 0, userInfo['id'])
        const demoCases = await activeCaseLoader(sdk, 'demo', 100, 0, userInfo['id'])

        const cases = [...userCases, ...demoCases]

        const affiliatedCompany = await affiliatedCompanyLoader(sdk, userInfo['id'])
        setAffiliatedCompany(affiliatedCompany)
        setCardList(cases)
      } catch (error) {
        console.error('Error fetching active cases:', error)
      }
      setCurrentView('activeCase')
    }

    fetchData()
  }, [userInfo])

  return (
    <Box
      sx={{
        backgroundColor: '#191919',
        height: '100vh',
        padding: '16px'
      }}
    >
      <CaseFilters
        isDemoChecked={isDemoChecked}
        setIsDemoChecked={setIsDemoChecked}
        genderFilter={genderFilter}
        setGenderFilter={setGenderFilter}
        indicationFilter={indicationFilter}
        setIndicationFilter={setIndicationFilter}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        isFilterPopoverOpen={isFilterPopoverOpen}
        handleFilterPopoverToggle={handleFilterPopoverToggle}
        anchorEl={filterButtonRef.current}
      />

      {/* Heading */}
      {/* need to change user name */}
      <Typography variant="h4" gutterBottom sx={{ color: 'white' }}>
        Hello Dr. {userInfo.firstName} {userInfo.lastName}
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
        {affiliatedCompany}
      </Typography>
      {/* Logo and Text */}
      {/* need to change user workplace  */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        {/* <BusinessIcon sx={logoIconStyle} />
        <Typography variant="body2" gutterBottom sx={{ color: 'white', marginLeft: '8px' }}>
          General University Hospital
        </Typography> */}
      </Box>

      {/* Active Patient Cases */}

      {/* Search and Filter */}

      {/* Grid of cases + Button that adds new case */}
      <ImageList
        cols={3}
        gap={16}
        sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}
      >
        <Box sx={{ width: '31%', paddingLeft: '0px', paddingRight: '20px' }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ color: 'white', fontWeight: '700', float: 'left' }}
          >
            Active Patient Cases
          </Typography>
        </Box>
        <Box sx={{ width: '31%', padding: '11px' }}></Box>
        <Box sx={{ width: '31%', paddingLeft: '20px', paddingRight: '0px' }}>
          <Button
            ref={filterButtonRef}
            sx={{ backgroundColor: '#036019', float: 'right' }}
            onClick={handleFilterPopoverToggle}
          >
            <FilterAltIcon sx={{ color: 'white' }} />
            <Typography
              variant="body2"
              gutterBottom
              sx={{ color: 'white', textTransform: 'initial' }}
            >
              Filter
            </Typography>
          </Button>
          <Button
            sx={{ backgroundColor: '#036019', float: 'right', marginRight: '1%' }}
            onClick={handleSortClick}
          >
            <SortIcon sx={{ color: 'white' }} />
            <Typography
              variant="body2"
              gutterBottom
              sx={{ color: 'white', textTransform: 'initial' }}
            >
              Sort
            </Typography>
          </Button>
          <Menu
            anchorEl={sortAnchorEl}
            open={Boolean(sortAnchorEl)}
            onClose={handleSortClose}
            slotProps={{
              paper: {
                sx: {
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '16px',
                  gap: '16px',
                  backgroundColor: '#232323',
                  color: '#FFFFFF'
                }
              }
            }}
          >
            <MenuItem onClick={() => handleSortOptionSelect('name')}>
              Sort by Name
              {sortOption === 'name' && sortDirection === 'asc' && (
                <ArrowUpwardIcon sx={{ fontSize: '1rem', ml: 1 }} />
              )}
              {sortOption === 'name' && sortDirection === 'desc' && (
                <ArrowDownwardIcon sx={{ fontSize: '1rem', ml: 1 }} />
              )}
            </MenuItem>
            <MenuItem onClick={() => handleSortOptionSelect('date')}>
              Sort by Date
              {sortOption === 'date' && sortDirection === 'asc' && (
                <ArrowUpwardIcon sx={{ fontSize: '1rem', ml: 1 }} />
              )}
              {sortOption === 'date' && sortDirection === 'desc' && (
                <ArrowDownwardIcon sx={{ fontSize: '1rem', ml: 1 }} />
              )}
            </MenuItem>
          </Menu>
          <Paper
            component="form"
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: '60%',
              backgroundColor: '#303030',
              marginRight: '1%',
              borderColor: 'white',
              borderWidth: '1px',
              float: 'right',
              borderStyle: 'solid' // Add white border style
            }}
          >
            <IconButton sx={{ p: '10px' }} aria-label="menu"></IconButton>
            <SearchIcon sx={{ color: 'white' }} />
            <InputBase
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ ml: 1, flex: 1, color: 'white' }}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search' }}
            />

            <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions"></IconButton>
          </Paper>
        </Box>
        {cardList.filter((cases) =>
          cases.data.filename.toLowerCase().includes(searchQuery.toLowerCase())
        ).length === 0 && (
          <Typography sx={{ color: 'white', width: '100%', textAlign: 'center' }}>
            No matching cases found.
          </Typography>
        )}
        {cardList
          .filter((cases) => !cases.data.filename.toLowerCase().includes('calcaneus_sample1'))
          .filter((cases) => !cases.data.filename.toLowerCase().includes('face_sample1'))
          .filter((cases) => !cases.data.filename.toLowerCase().includes('face_sample3'))
          .filter((cases) => cases.data.filename.toLowerCase().includes(searchQuery.toLowerCase()))
          .filter((cases) => {
            // Demo filter logic...
            if (isDemoChecked) {
              return cases.data.user === 'demo' || cases.data.user !== 'demo'
            } else {
              return cases.data.user !== 'demo'
            }
          })
          .filter((cases) => {
            // Apply the gender filter
            const gender = cases.data.patientInfo?.gender || 'Other' // Treat undefined gender as 'Other'

            // Check if at least one of the selected gender filters applies
            const isMale = genderFilter.male && gender === 'Male'
            const isFemale = genderFilter.female && gender === 'Female'
            const isOther = genderFilter.other && gender === 'Other'

            // If all gender checkboxes are unchecked, return false
            if (!genderFilter.male && !genderFilter.female && !genderFilter.other) {
              return false
            }

            // Return true if any of the gender conditions are met
            return isMale || isFemale || isOther
          })
          .filter((cases) => {
            // Apply the indication filter
            const indicationsSelected = Object.keys(indicationFilter).filter(
              (key) => indicationFilter[key]
            )
            const caseIndication = cases.data.indication || 'Others' // Treat empty or undefined indication as 'Others'

            // If no indication checkboxes are checked, return false (show no cases)
            if (indicationsSelected.length === 0) {
              return false
            }

            // If 'Others' is one of the selected indications
            const othersSelected = indicationsSelected.includes('Others')

            // Check if the case's indication matches any of the selected indications
            // or if 'Others' is selected and the case's indication is not in the indicationFilter keys
            return (
              indicationsSelected.includes(caseIndication) ||
              (othersSelected && !Object.keys(indicationFilter).includes(cases.data.indication))
            )
          })
          .filter((cases) => {
            // Check if no checkboxes are checked, if so, return false to show no cases
            if (!statusFilter.complete && !statusFilter.notComplete) {
              return false
            }

            // If both checkboxes are checked, return true to show all cases
            if (statusFilter.complete && statusFilter.notComplete) {
              return true
            }

            // Apply the filter based on what's checked
            if (statusFilter.complete && cases.data.status !== 'complete') return false
            if (statusFilter.notComplete && cases.data.status === 'complete') return false

            return true
          })
          .sort((a, b) => {
            const multiplier = sortDirection === 'asc' ? 1 : -1

            switch (sortOption) {
              case 'name':
                return multiplier * a.data.filename.localeCompare(b.data.filename)
              case 'date':
                return multiplier * (new Date(a.creationTimestamp) - new Date(b.creationTimestamp))
              default:
                return 0
            }
          })
          .map((cases) => (
            <ActiveCaseCard
              sdk={sdk}
              cases={cases}
              setCurrentView={setCurrentView}
              setIsLoading={setIsLoading}
              filePath={filePath}
              setFilePath={setFilePath}
              setButtonState={setButtonState}
              setIsRenderingThreeDisabled={setIsRenderingThreeDisabled}
              setDemo={setDemo}
              setIndication={setIndication}
              setBackInfo={setBackInfo}
              backInfo={backInfo}
              key={cases.id}
              sx={{ flex: '0 0 calc(33.33% - 10px)', height: '100%' }}
            />
          ))}

        {/* new case button to connect create case view */}
        <AddNewCaseButton setCurrentView={setCurrentView} sx={{ height: '100%' }} />
      </ImageList>
    </Box>
  )
}

export default ActiveCaseList

// const logoIconStyle = {
//   color: 'rgb(255, 255, 255)',
//   display: 'inline-block',
//   height: 15,
//   width: 15
// }
