/* eslint-disable react/prop-types */
import React from 'react'
import * as THREE from 'three'

import { TrackballControls } from './TrackballControlls'

import { useEffect, useRef, useState } from 'react'
import Stats from 'stats.js'
import { removeObject } from './SegmentToolHandler'
// import { targetLoadObj } from '../functions/targetAnatomy/TargetLoadObj'
import { addLights } from '../functions/labelPositioning/AddLights'

import { colorMapArray } from './ColorMap.jsx'

import {
  addBoundingBoxAndLabels,
  toggleBoundingBoxVisibility,
  calculateNewCameraPosition
} from './ViewBoxUtils.jsx'

import requestAnimationFrame from 'dat.gui/src/dat/utils/requestAnimationFrame'

const RenderingThreeSceneTargetAnatomy = ({
  mainGUIRef: mainGUIRef,
  filePath: filePath,
  viewBoxRef4: viewBoxRef4,
  stats: stats,
  checkedValues: checkedValues,
  labelColorSwitch: labelColorSwitch,
  cameraResetClicked: cameraResetClicked,
  setCameraResetClicked: setCameraResetClicked,
  rotationVector: rotationVector,
  setRotationVector: setRotationVector,
  viewBoxOn: viewBoxOn,
  setIsLoading: setIsLoading,
  repLoadedObjects: repLoadedObjects,
  rotateSpeed: rotateSpeed
}) => {
  const containerRef = useRef(null)
  const scene = useRef(new THREE.Scene())
  const cameraRef = useRef()
  const rendererRef = useRef()
  const controlsRef = useRef()
  const initFinished = useRef(false)

  const [initBool, setInitBool] = useState(false)

  const [initialCameraState, setInitialCameraState] = useState({
    set: false,
    camera: null
  })

  const [objectsLoaded, setObjectsLoaded] = useState({})

  const cameraPositionsRef = useRef({})
  const boundingBoxMeshRef = useRef(null)

  let lastTime = useRef(Date.now())
  const renderLoopRef = useRef(null)
  const fps = 30
  const interval = 1000 / fps

  function tick() {
    const now = Date.now()
    const elapsed = now - lastTime.current

    if (elapsed > interval) {
      lastTime.current = now - (elapsed % interval)

      if (stats != null) {
        stats.update()
      }

      // Ensure trackball controls are updated in the animation loop
      if (controlsRef.current != null) {
        controlsRef.current.update()
      }

      render()
    }

    requestAnimationFrame(tick)
  }

  function animate() {
    if (renderLoopRef.current === null) {
      renderLoopRef.current = requestAnimationFrame(tick)
      console.debug('render loop initiated')
    } else {
      console.debug('render loop already initiated, ignoring')
    }
  }

  function render() {
    rendererRef.current.render(scene.current, cameraRef.current)
  }

  const updateSize = () => {
    setTimeout(() => {
      rendererRef.current.setSize(viewBoxRef4.current.clientWidth, viewBoxRef4.current.clientHeight)
    }, 5000)
    rendererRef.current.setPixelRatio(window.devicePixelRatio)
    render()
  }

  useEffect(() => {
    if (!viewBoxRef4.current) {
      return
    }
    mainGUIRef.current.hide()
    if (!stats) {
      stats = new Stats()
      stats.showPanel(2) // 0: fps, 1: ms, 2: mb, 3+: custom

      stats.dom.style.position = 'fixed'
      stats.dom.style.top = '0'
      stats.dom.style.left = 'auto'
      stats.dom.style.right = '0'
      stats.dom.id = 'statsContainer' // Assign a specific name to the stats.dom element
      document.body.appendChild(stats.dom)
    }

    const width = viewBoxRef4.current.clientWidth
    const height = viewBoxRef4.current.clientHeight

    // const scene = new THREE.Scene()

    const renderer = new THREE.WebGLRenderer()
    rendererRef.current = renderer
    renderer.sortObjects = false
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(width, height)
    containerRef.current.appendChild(renderer.domElement)

    // Orthographic Cameras are supposed to be used for 2D views
    // for 3D views THREE.PerspectiveCamera  is supposed to be used.
    // However since the 3D files are not "proper" 3D objects just some texture files
    // that make the impression of a 3D object the PerspectiveCamera cant be used because
    // it creates are weird perception
    const camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 20000)
    camera.position.z = 250
    cameraRef.current = camera

    const controls = new TrackballControls(camera, renderer.domElement)
    controls.rotateSpeed = 1.0
    controls.zoomSpeed = 1.0
    controls.panSpeed = 0.5
    controls.enabled = false
    controlsRef.current = controls

    controls.target.set(0, 0, 0)
    controls.minZoom = 0.5
    controls.maxZoom = 4
    controls.enablePan = true
    controls.enableRotate = true

    addLights(scene.current)

    window.addEventListener('resize', updateSize)

    updateSize()

    setInitBool(true)

    return () => {
      // Clean up the animation loop when component unmounts
      if (containerRef.current) {
        containerRef.current.removeChild(renderer.domElement)
        rendererRef.current.dispose()

        window.removeEventListener('resize', updateSize)
        if (!stats) {
          document.body.removeChild(stats.dom)
        }
      }
      removeObject(scene.current, 'groupLights')
    }
  }, [mainGUIRef, viewBoxRef4])

  useEffect(() => {
    const allKeys = Object.keys(filePath.repositionObjects) // Keys that should be loaded
    const allLoaded = allKeys.every((key) =>
      Object.prototype.hasOwnProperty.call(repLoadedObjects, key)
    ) // Check if all keys are loaded

    if (!allLoaded) {
      // If not all objects are loaded, exit early
      return
    }

    if (initBool && initFinished.current === false) {
      Object.entries(repLoadedObjects).forEach(([objectName, { object }]) => {
        object.name = objectName
        scene.current.add(object)

        // Render the scene immediately after adding the object
        rendererRef.current.render(scene.current, cameraRef.current)

        // Update the loading state for this object
        setObjectsLoaded((prev) => ({ ...prev, [objectName]: true }))
      })
      initFinished.current = true

      updateSize()
      animate()
    }
  }, [initBool, repLoadedObjects])

  useEffect(() => {
    if (Object.keys(checkedValues).length !== 0) {
      if (scene.current !== null) {
        scene.current.children.forEach((child) => {
          // Assume the child's name or some other property maps to the keys in checkedValues
          const childId = parseInt(child.name.replace('label', '')) // This is an assumption based on your structure

          // If childId is not NaN and we have a corresponding checkedValue...
          if (!isNaN(childId) && Object.prototype.hasOwnProperty.call(checkedValues, childId)) {
            // Set the visibility based on the checkedValue
            child.visible = checkedValues[childId]
          }
        })

        animate()
      }
    }
  }, [checkedValues])

  useEffect(() => {
    // Define the neutral color (e.g., light gray RGB)
    const neutralColor = new THREE.Color(1.0, 1.0, 1.0) // Corresponds to 211/255

    const colors = colorMapArray.map((c) => c / 255)

    scene.current.children.forEach((child) => {
      if (child.name.startsWith('label')) {
        // Extract label number from child name
        const labelNumber = parseInt(child.name.replace('label', ''))

        // Calculate the index for the color in the 'colors' array
        const colorIndex = labelNumber * 3

        // Get color for the label or use neutral color based on the switch state
        const color = labelColorSwitch
          ? new THREE.Color(colors[colorIndex], colors[colorIndex + 1], colors[colorIndex + 2])
          : neutralColor

        // Apply color to the geometry of the child object
        child.traverse((subChild) => {
          if (subChild.isMesh && subChild.geometry && subChild.geometry.attributes.color) {
            const colorsArray = subChild.geometry.attributes.color.array
            for (let i = 0, n = colorsArray.length; i < n; i += 3) {
              colorsArray[i] = color.r
              colorsArray[i + 1] = color.g
              colorsArray[i + 2] = color.b
            }
            subChild.geometry.attributes.color.needsUpdate = true
          }
        })
      }
    })

    // Trigger a render update if necessary
    animate()
  }, [labelColorSwitch])

  useEffect(() => {
    if (cameraResetClicked) {
      if (initialCameraState.set && cameraRef.current && controlsRef.current) {
        // Reset camera
        cameraRef.current.copy(initialCameraState.camera)

        // Reset controls
        controlsRef.current.target.copy(initialCameraState.controlsTarget)

        setCameraResetClicked(false)
        animate()
      }
    }
  }, [cameraResetClicked])

  useEffect(() => {
    if (rotationVector !== null) {
      let z = cameraRef.current.position.z
      let y = cameraRef.current.position.y
      let x = cameraRef.current.position.x
      const zoomValue = Math.max(Math.abs(z), Math.abs(x), Math.abs(y))
      // Copy the initial camera and control targets
      cameraRef.current.copy(cameraPositionsRef.current.camera)
      controlsRef.current.target.copy(cameraPositionsRef.current.controlsTarget)

      // Calculate and set the new camera position based on rotationVector
      const newPosition = calculateNewCameraPosition(
        cameraPositionsRef.current.camera.position,
        cameraPositionsRef.current.controlsTarget,
        rotationVector
      )
      cameraRef.current.position.copy(newPosition)

      // Special handling for 'S' and 'I' to adjust the camera's up vector and lookAt
      if (rotationVector === 'S') {
        cameraRef.current.up.set(0, 0, -1) // For looking down
      } else if (rotationVector === 'I') {
        cameraRef.current.up.set(0, 0, 1) // For looking up
      }
      if (rotationVector === 'S' || rotationVector === 'I') {
        cameraRef.current.lookAt(controlsRef.current.target) // Ensure correct orientation
        cameraRef.current.position.y = rotationVector === 'I' ? -zoomValue : zoomValue
      } else if (rotationVector === 'A' || rotationVector === 'P') {
        cameraRef.current.position.z = rotationVector === 'P' ? -zoomValue : zoomValue
      } else {
        cameraRef.current.position.x = rotationVector === 'L' ? zoomValue : -zoomValue
      }

      animate() // Update the scene
      setRotationVector(null) // Reset rotationVector to avoid re-triggering
    }
  }, [rotationVector])

  useEffect(() => {
    // Check if all objects are loaded based on filePath.repositionObjects keys
    const allKeys = Object.keys(filePath.repositionObjects) // Keys that should be loaded
    const allLoaded = allKeys.every((key) => objectsLoaded[key] === true) // Check if all keys are loaded

    if (initFinished.current && allLoaded) {
      // Perform actions after all objects have been loaded
      controlsRef.current.enabled = true

      // Remove all existing 'BoundingBox' objects from the scene
      scene.current.children = scene.current.children.filter(
        (child) => child.name !== 'BoundingBox'
      )
      boundingBoxMeshRef.current = addBoundingBoxAndLabels(scene.current)

      if (cameraRef.current && controlsRef.current) {
        // Set the camera position and orientation for rotationVector = "I" (Inferior view)
        const zoomValue = Math.max(
          Math.abs(cameraRef.current.position.z),
          Math.abs(cameraRef.current.position.x),
          Math.abs(cameraRef.current.position.y)
        )

        // Position the camera below the target for an Inferior view
        cameraRef.current.position.set(0, -zoomValue, 0) // Below the object
        cameraRef.current.up.set(0, 0, 1) // Camera's up vector pointing along the Z-axis
        cameraRef.current.lookAt(controlsRef.current.target) // Ensure the camera looks at the target
      }

      // Now, save this as the initial camera state
      if (!initialCameraState.set && cameraRef.current && controlsRef.current) {
        setInitialCameraState({
          set: true,
          camera: cameraRef.current.clone(), // Save the cloned state of the camera
          controlsTarget: controlsRef.current.target.clone() // Save the cloned state of the controls' target
        })
      }

      // Save these initial positions for possible resets
      cameraPositionsRef.current = {
        camera: cameraRef.current.clone(),
        controlsTarget: controlsRef.current.target.clone()
      }

      setIsLoading(false)
      animate()
    }
  }, [initFinished, objectsLoaded])

  useEffect(() => {
    if (viewBoxOn === true) {
      toggleBoundingBoxVisibility(boundingBoxMeshRef.current, true) // Show
    } else {
      toggleBoundingBoxVisibility(boundingBoxMeshRef.current, false) // Hide
    }
  }, [viewBoxOn])

  useEffect(() => {
    if (controlsRef.current) {
      controlsRef.current.rotateSpeed = rotateSpeed
    }
  }, [rotateSpeed])

  return <div name="3DBox" ref={containerRef} />
}

export default RenderingThreeSceneTargetAnatomy
