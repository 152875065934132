/* eslint-disable react/prop-types */
import { Box, CircularProgress } from '@mui/material'
import CircularProgressWithLabel from './components/maintool/functions/others/CirCularProgressWithLabel'
import { ThemeProvider } from '@mui/material/styles'
import React, { useEffect, useState, useRef } from 'react'
import { appTheme } from './Theme'

import ActiveCaseList from './components/caseOverview/activeCases/ActiveCaseList'
import CreateNewCase from './components/caseOverview/createCase/CreateNewCase'
import Header from './components/navbar/Header'
import LeftBar from './components/navbar/LeftBar'
import TwoDViews from './components/maintool/TwoDViews'
import ClosedCase from './components/caseOverview/closedCases/ClosedCases'
import SessionCounter from './components/maintool/functions/others/SessionCounter'

import LogoutDialog from './components/authentication/Logout'
import ChangeCasesDialog from './components/authentication/ChangeCases'

function App({ sdk: sdk, userInfo: userInfo }) {
  const [currentView, setCurrentView] = useState('activeCase') // current view of page. after login, you can see activecase
  const [isLoading, setIsLoading] = useState(false) // loading prompt
  const [isSaving, setIsSaving] = useState(false) // saving prompt
  const [isRenderingThreeDisabled, setIsRenderingThreeDisabled] = useState(true) // check rendering is enabled or not for main view
  const [filePath, setFilePath] = useState({
    filename: '',
    image: '',
    seg: '',
    iso: '',
    reg: '',
    reg_image: '',
    regObj: '',
    target_image: '',
    target: '',
    isolationObjects: {},
    repositionObjects: {},
    repositionTransformMatrix: {},
    manualRepositionTransformMatrix: {},
    targetObjects: {},
    side: '',
    model: {
      image: '',
      seg: '',
      iso: '',
      regObj: '',
      isolationObjects: {},
      repositionObjects: {},
      targetObjects: {},
      repositionTransformMatrix: {}
    }
  }) // use to load volume and label data with nifti loader
  // const buttonStates = ["None", "Image", "Seg", "Iso", "Rep", "Target", "Screw", "Implant", "Sim", "Opt"]; // full feature of header button states
  const buttonStates = ['None', 'Image', 'Seg', 'Iso', 'Rep', 'Target', 'Implant'] // first version of header button states name
  const [buttonState, setButtonState] = useState(buttonStates[0]) //current button state
  const [currentButton, setCurrentButton] = useState('None')
  const [isDemo, setDemo] = useState(false) // check it is demo files or not
  const [indication, setIndication] = useState('') // save indication of file

  const [volume, setVolume] = useState(null) // volume object
  const [label, setLabel] = useState(null) // label object

  const buttonType = useRef('') // file upload type : file or folder

  const [backInfo, setBackInfo] = useState({
    schemaID: '',
    documentID: ''
  }) // backend information

  const [mainView, setMainView] = useState('whole') // the state of current view,[whole, view1, view2, view3, view4]. corresponding mainView in TwoDViews.jsx
  const [on3DRender, setOn3DRender] = useState({ on: false }) // 3D rendering view button is on or off

  const [measureConfig, setMeasureConfig] = useState({ type: 'None' }) // configuration for measurement tool

  const [movementConfig, setMovementConfig] = useState({ type: 'none' }) // configuration for movement tool
  const [automatedPlacement, setAutomatedPlacement] = useState(true) // useState for automated Placement of the implant on the bone using only clicking
  const [centerPoint, setCenterPoint] = useState(true) //useState to switch between rotation point to be in the geometrical center or a point on the object
  const [labelColorSwitch, setLabelColorSwitch] = useState(true) //useState to switch between rotation point to be in the geometrical center or a point on the object
  const [currentMovementMenu, setCurrentMovementMenu] = useState('none')

  const [movementActivation, setMovementActivation] = useState(false)
  movementActivation

  const [isMovementExpanded, setMovementsExpanded] = useState(false) // useState for movement extension status
  const [showFlipBox, setShowFlipBox] = useState(false)
  const [flip, setFlip] = useState({ type: null })

  const [Radius, setRadius] = useState({ radius: 5 }) // radius for segmentation tool, especially brush and eraser
  const [selectedSegment, setSelectedSegment] = useState(1) // segmentation label number
  const [segmentConfig, setSegmentConfig] = useState({ type: 'None' }) // configuration for segmentation tool
  // types : 'None', 'Segmentation', 'Erase', 'Scissor Seg', 'Scissor Erase'

  const [showToolBox, setShowToolBox] = useState('None') // useState for the types of toolbox

  const [brightness, setBrightness] = useState({ brightness: 1 }) // useState for brigntness to modify volume brightness
  const [contrast, setContrast] = useState({ contrast: 0 }) // useState for contract to modify volume contrast

  const [ISOThreshold, setISOThreshold] = useState({ min: 0.0, max: 5000.0 })
  // const [ISOThresholdRange, setISOThresholdRange] = useState({ min: 0.0, max: 0.0 })
  // ISOThresholdRange, setISOThresholdRange

  const [volumeRange, setVolumeRange] = useState({ min: 0.0, max: 0.0 }) // the min and max value of volume intensity
  const [labelThresholdRange, SetLabelThresholdRange] = useState({ min: 0.0, max: 0.0 }) // the min and max value of threshold to apply label
  const [implantToken, setImplantToken] = useState({ token: '', description: '' }) // useState for Implant name
  const [isSegmentationVisible, setIsSegmentationVisible] = useState(true) // useState for segmentation in target anotomy
  const [isVolumeVisible, setIsVolumeVisible] = useState(false) // useState for volume in 3D volume

  const [loadingValue, setLoadingValue] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [nextButtonType, setNextButtonType] = useState(null)

  // variables for label box
  const [selectedValue, setSelectedValue] = useState(null)
  const [checkedValues, setCheckedValues] = useState({})
  const [uniqueValues, setUniqueValues] = useState([])

  const [isSegmentToolAffected, setIsSegmentToolAffected] = useState(false)
  const [isUndo, setIsUndo] = useState(false)
  const [isRedo, setIsRedo] = useState(false)
  const [isRestore, setIsRestore] = useState(false)
  const [undoStack, setUndoStack] = useState([])
  const [redoStack, setRedoStack] = useState([])

  const [viewBoxOn, setViewBoxOn] = useState(false)
  const [animationOn, setAnimationOn] = useState(false)

  const [crosshairsOn, setCrosshairsOn] = useState(false)
  const [objectPositionInfo, setObjectPositionInfo] = useState({
    init: false,
    trans: null,
    rotation: null
  })

  const [manualRepositionMatrix, setManualRepositionMatrix] = useState({})

  const [openWarnDialog, setOpenWarnDialog] = useState(false)
  const [warnMessage, setWarnMessage] = useState('Please wait for the ongoing process to complete!')

  const [autoPlacementInfo, setAutoPlacementInfo] = useState(false)
  const [translationInfo, setTranslationInfo] = useState(false)

  const [sliderSagittal, setsliderSagittal] = useState({
    current: -1,
    max: 0
  })

  const [sliderAxial, setsliderAxial] = useState({
    current: -1,
    max: 0
  })

  const [sliderCoronal, setsliderCoronal] = useState({
    current: -1,
    max: 0
  })

  const [isClickedCase, setIsClickedCase] = useState(false)

  const [repoScene, setRepoScene] = useState(null)
  const [isoSliderInfo, setIsoSliderInfo] = useState({
    initialized: false,
    min: null,
    max: null
  })

  const isoThresholdMinimumDefault = 250

  const backEndState = [
    'none',
    'uploaded_file',
    'seg_preprocessed',
    'segmented',
    'seg_postprocessed',
    'segmented_file',
    'iso_preprocessed',
    'isolated',
    'iso_postprocessed',
    'isolated_file',
    'repo_preprocessed',
    'shape_completed',
    'matched',
    'registered',
    'repo_postprocessed',
    'complete'
  ]

  const [isFirstfileSelected, setIsFirstfileSelected] = useState(false)
  const [cameraResetClicked, setCameraResetClicked] = useState(false)

  const handleClickOpen = (type) => {
    setOpenConfirmDialog(true)
    setNextButtonType(type)
  }

  const handleWarnDialog = (state) => {
    setOpenWarnDialog(state)
  }
  const [selectedView, setSelectedView] = useState('')
  const [automatedPlacementStatus, setAutomatedPlacementStatus] = useState({
    firstImplantPoint: 'Not defined',
    firstBonePoint: 'Not defined',
    secondImplantPoint: 'Not defined',
    secondBonePoint: 'Not defined',
    thirdImplantPoint: 'Not defined',
    thirdBonePoint: 'Not defined'
  })

  const [niftiProgress, setNiftiProgress] = useState({
    loadingStatus: 'done',
    filesize: 0
  })

  const [dots, setDots] = useState('')

  const [allViewLoaded, SetAllViewLoaded] = useState(false)

  const [dialogOpen, setDialogOpen] = useState('closed')
  const [activeButton, setActiveButton] = useState('activeCase')
  const [redirectTo, setRedirectTo] = useState(false)

  const [rotateSpeed, setRotateSpeed] = useState(1.0)

  const handleClose = () => {
    setDialogOpen('closed')
  }

  const getLoadingMessage = () => {
    if (niftiProgress.loadingStatus === 'downloading') {
      return `${niftiProgress.filesize.toFixed(2)} MB of data is downloading and processing${dots}`
    } else if (niftiProgress.loadingStatus === 'rendering') {
      return `Data is being rendered${dots}`
    } else {
      return `Loading${dots}`
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''))
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (isMovementExpanded == false) {
      setMovementConfig({ type: 'none' })
      setAutoPlacementInfo(false)
      setCurrentMovementMenu('none')
    }
  }, [isMovementExpanded])

  // apply styles defined in bottom of this script
  useEffect(() => {
    const styleElement = document.querySelector('style')

    if (styleElement) {
      styleElement.innerHTML += styles
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount
  useEffect(() => {
    const statsContainer = document.getElementById('statsContainer')

    if (statsContainer && statsContainer.parentNode === document.body && buttonState !== 'main') {
      document.body.removeChild(statsContainer)
    }
  }, [buttonState, currentView])

  return (
    <UserContext.Provider
      value={{
        loadingValue: loadingValue,
        setLoadingValue: setLoadingValue,
        openConfirmDialog: openConfirmDialog,
        setOpenConfirmDialog: setOpenConfirmDialog,
        nextButtonType: nextButtonType,
        setNextButtonType: setNextButtonType,
        handleClickOpen: handleClickOpen,
        backEndState: backEndState,
        selectedValue: selectedValue,
        setSelectedValue: setSelectedValue,
        checkedValues: checkedValues,
        setCheckedValues: setCheckedValues,
        uniqueValues: uniqueValues,
        setUniqueValues: setUniqueValues,
        isSegmentToolAffected: isSegmentToolAffected,
        setIsSegmentToolAffected: setIsSegmentToolAffected,
        isUndo: isUndo,
        setIsUndo: setIsUndo,
        isRedo: isRedo,
        setIsRedo: setIsRedo,
        undoStack: undoStack,
        setUndoStack: setUndoStack,
        redoStack: redoStack,
        setRedoStack: setRedoStack,
        setIsLoading: setIsLoading,
        isLoading: isLoading,
        openWarnDialog: openWarnDialog,
        setOpenWarnDialog: setOpenWarnDialog,
        handleWarnDialog: handleWarnDialog,
        warnMessage: warnMessage,
        setWarnMessage: setWarnMessage,
        sliderSagittal: sliderSagittal,
        setsliderSagittal: setsliderSagittal,
        sliderAxial: sliderAxial,
        setsliderAxial: setsliderAxial,
        sliderCoronal: sliderCoronal,
        setsliderCoronal: setsliderCoronal,
        isClickedCase: isClickedCase,
        setIsClickedCase: setIsClickedCase,
        repoScene: repoScene,
        setRepoScene: setRepoScene,
        setIsoSliderInfo: setIsoSliderInfo,
        setISOThreshold: setISOThreshold,
        volumeRange: volumeRange,
        ISOThreshold: ISOThreshold,
        setMainView: setMainView,
        setAutomatedPlacement: setAutomatedPlacement,
        automatedPlacement: automatedPlacement,
        setMovementConfig: setMovementConfig,
        buttonState: buttonState,
        setImplantToken: setImplantToken,
        implantToken: implantToken,
        setIsFirstfileSelected: setIsFirstfileSelected,
        isFirstfileSelected: isFirstfileSelected,
        currentButton: currentButton,
        setCurrentButton: setCurrentButton,
        setCurrentView: setCurrentView,
        centerPoint: centerPoint,
        setCenterPoint: setCenterPoint,
        currentMovementMenu: currentMovementMenu,
        setCurrentMovementMenu: setCurrentMovementMenu,
        filePath: filePath,
        setFilePath: setFilePath,
        labelColorSwitch: labelColorSwitch,
        setLabelColorSwitch: setLabelColorSwitch,
        setCameraResetClicked: setCameraResetClicked,
        cameraResetClicked: cameraResetClicked,
        isRestore: isRestore,
        setIsRestore: setIsRestore,
        translationInfo: translationInfo,
        setTranslationInfo: setTranslationInfo,
        flip: flip,
        setFlip: setFlip,
        setShowFlipBox: setShowFlipBox,
        showFlipBox: showFlipBox,
        objectPositionInfo: objectPositionInfo,
        setObjectPositionInfo: setObjectPositionInfo,
        setViewBoxOn: setViewBoxOn,
        viewBoxOn: viewBoxOn,
        animationOn: animationOn,
        setAnimationOn: setAnimationOn,
        setCrosshairsOn: setCrosshairsOn,
        crosshairsOn: crosshairsOn,
        manualRepositionMatrix: manualRepositionMatrix,
        setManualRepositionMatrix: setManualRepositionMatrix,
        sdk: sdk,
        niftiProgress: niftiProgress,
        setNiftiProgress: setNiftiProgress,
        allViewLoaded: allViewLoaded,
        SetAllViewLoaded: SetAllViewLoaded,
        setRotateSpeed: setRotateSpeed,
        rotateSpeed: rotateSpeed
      }}
    >
      <ThemeProvider theme={appTheme}>
        <Box
          sx={{
            backgroundColor: appTheme.palette.background.main,
            height: '100vh',
            padding: 0,
            margin: 0,
            display: 'flex'
          }}
        >
          <Box
            sx={{
              display: isLoading ? 'flex' : 'none',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 9000 // set high z-index value
            }}
          >
            <Box
              sx={{
                padding: '20px',
                border: '1px solid #888',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: '0 5px 8px 0 rgba(0, 0, 0, 0.2)',
                backgroundColor: '#232323'
              }}
            >
              <CircularProgressWithLabel
                loadingValue={loadingValue}
                niftiProgress={niftiProgress}
              />
              <Box
                sx={{
                  color: appTheme.palette.common.white,
                  marginTop: '16px',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  textAlign: 'center'
                }}
              >
                {getLoadingMessage()}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: isSaving ? 'flex' : 'none',
              position: 'absolute',
              bottom: '50%',
              left: '50%',
              zIndex: 9000 // set high z-index value
            }}
            className="modal"
          >
            <Box sx={{ backgroundColor: '#232323' }} className="modal-content">
              <CircularProgress />
              <Box sx={{ color: appTheme.palette.common.white }} className="modal-text">
                Saving...
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              marginLeft: '90px' // Adjust this value as needed to create the desired separation
            }}
          >
            {currentView === 'main' && sdk && !isRenderingThreeDisabled && (
              <>
                {false && <SessionCounter />}
                <Header
                  filePath={filePath}
                  setFilePath={setFilePath}
                  setButtonState={setButtonState}
                  sdk={sdk}
                  userInfo={userInfo}
                  isDemo={isDemo}
                  backInfo={backInfo}
                  setBackInfo={setBackInfo}
                  setIsLoading={setIsLoading}
                  buttonStates={buttonStates}
                  buttonState={buttonState}
                  indication={indication}
                  label={label}
                  mainView={mainView}
                  setMainView={setMainView}
                  on3DRender={on3DRender}
                  setOn3DRender={setOn3DRender}
                  setIsSaving={setIsSaving}
                  setMeasureConfig={setMeasureConfig}
                  Radius={Radius}
                  setRadius={setRadius}
                  setSegmentConfig={setSegmentConfig}
                  setSelectedSegment={setSelectedSegment}
                  showToolBox={showToolBox}
                  setShowToolBox={setShowToolBox}
                  brightness={brightness}
                  setBrightness={setBrightness}
                  contrast={contrast}
                  setContrast={setContrast}
                  volumeRange={volumeRange}
                  SetLabelThresholdRange={SetLabelThresholdRange}
                  setMovementConfig={setMovementConfig}
                  setMovementActivation={setMovementActivation}
                  setImplantToken={setImplantToken}
                  // setRotationConfig={setRotationConfig}
                  isSegmentationVisible={isSegmentationVisible}
                  setIsSegmentationVisible={setIsSegmentationVisible}
                  isVolumeVisible={isVolumeVisible}
                  setIsVolumeVisible={setIsVolumeVisible}
                  ISOThreshold={ISOThreshold}
                  setISOThreshold={setISOThreshold}
                  isoSliderInfo={isoSliderInfo}
                  setIsoSliderInfo={setIsoSliderInfo}
                  currentMovementMenu={currentMovementMenu}
                  setCurrentMovementMenu={setCurrentMovementMenu}
                  centerPoint={centerPoint}
                  setCenterPoint={setCenterPoint}
                  autoPlacementInfo={autoPlacementInfo}
                  setAutoPlacementInfo={setAutoPlacementInfo}
                  isMovementExpanded={isMovementExpanded}
                  setMovementsExpanded={setMovementsExpanded}
                  isoThresholdMinimumDefault={isoThresholdMinimumDefault}
                  setSelectedView={setSelectedView}
                />
                <TwoDViews
                  filePath={filePath}
                  buttonState={buttonState}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  setVolume={setVolume}
                  volume={volume}
                  setLabel={setLabel}
                  label={label}
                  mainView={mainView}
                  setMainView={setMainView}
                  on3DRender={on3DRender}
                  setOn3DRender={setOn3DRender}
                  measureConfig={measureConfig}
                  Radius={Radius}
                  segmentConfig={segmentConfig}
                  selectedSegment={selectedSegment}
                  showToolBox={showToolBox}
                  brightness={brightness}
                  contrast={contrast}
                  volumeRange={volumeRange}
                  setVolumeRange={setVolumeRange}
                  labelThresholdRange={labelThresholdRange}
                  movementConfig={movementConfig}
                  implantToken={implantToken}
                  isSegmentationVisible={isSegmentationVisible}
                  isVolumeVisible={isVolumeVisible}
                  ISOThreshold={ISOThreshold}
                  isoSliderInfo={isoSliderInfo}
                  setIsoSliderInfo={setIsoSliderInfo}
                  setAutomatedPlacement={setAutomatedPlacement}
                  automatedPlacement={automatedPlacement}
                  currentPanel={currentView}
                  setMovementConfig={setMovementConfig}
                  setCurrentMovementMenu={setCurrentMovementMenu}
                  centerPoint={centerPoint}
                  autoPlacementInfo={autoPlacementInfo}
                  setAutoPlacementInfo={setAutoPlacementInfo}
                  isMovementExpanded={isMovementExpanded}
                  automatedPlacementStatus={automatedPlacementStatus}
                  setAutomatedPlacementStatus={setAutomatedPlacementStatus}
                  isoThresholdMinimumDefault={isoThresholdMinimumDefault}
                  selectedView={selectedView}
                  indication={indication}
                />
              </>
            )}

            {/* load different view depends on leftbar buttons */}
            {currentView === 'activeCase' && sdk && (
              <>
                {' '}
                {false && <SessionCounter />}
                <ActiveCaseList
                  sdk={sdk}
                  setCurrentView={setCurrentView}
                  setIsLoading={setIsLoading}
                  filePath={filePath}
                  setFilePath={setFilePath}
                  setButtonState={setButtonState}
                  setIsRenderingThreeDisabled={setIsRenderingThreeDisabled}
                  setDemo={setDemo}
                  userInfo={userInfo}
                  setIndication={setIndication}
                  setBackInfo={setBackInfo}
                  backInfo={backInfo}
                  setOn3DRender={setOn3DRender}
                />
              </>
            )}

            {currentView === 'createCase' && sdk && (
              <>
                {' '}
                {false && <SessionCounter />}
                <CreateNewCase
                  buttonType={buttonType}
                  isDemo={isDemo}
                  setDemo={setDemo}
                  isRenderingThreeDisabled={isRenderingThreeDisabled}
                  setIsRenderingThreeDisabled={setIsRenderingThreeDisabled}
                  setIsLoading={setIsLoading}
                  backInfo={backInfo}
                  setBackInfo={setBackInfo}
                  setButtonState={setButtonState}
                  userInfo={userInfo}
                  filePath={filePath}
                  setFilePath={setFilePath}
                  sdk={sdk}
                  setCurrentView={setCurrentView}
                  indication={indication}
                  setIndication={setIndication}
                  setOn3DRender={setOn3DRender}
                />
              </>
            )}

            {currentView === 'closedCase' && <ClosedCase />}
          </Box>

          <LeftBar
            currentView={currentView}
            setCurrentView={setCurrentView}
            setIsRenderingThreeDisabled={setIsRenderingThreeDisabled}
            on3DRender={on3DRender}
            setOn3DRender={setOn3DRender}
            activeButton={activeButton}
            setActiveButton={setActiveButton}
            setRedirectTo={setRedirectTo}
            setDialogOpen={setDialogOpen}
          />
        </Box>

        <LogoutDialog open={dialogOpen} onClose={handleClose} />
        <ChangeCasesDialog
          open={dialogOpen}
          onClose={handleClose}
          setActiveButton={setActiveButton}
          setRedirectTo={setRedirectTo}
          redirectTo={redirectTo}
          setCurrentView={setCurrentView}
        />
      </ThemeProvider>
    </UserContext.Provider>
  )
}

export default App
export const UserContext = React.createContext(null)

const styles = `
.App {
    text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.option-content {
    margin: auto;
    padding: 30px;
    border: 1px solid #888;
    border-radius: 5px;
    display: flex;
    align-items: center;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #fefefe;
    flex-direction: column !important;
}

.demo-content {
    margin: auto;
    padding: 30px;
    border: 1px solid #888;
    border-radius: 5px;
    display: flex;
    align-items: center;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #fefefe;
}

.modal-content {
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 5px;
    display: flex;
    align-items: center;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #fefefe;
}

.modal-text {
    margin-left: 14px;
    font-size: 24px;
}

.loader {
    width: 50px;
    height: 50px;
    border: 8px solid #ddd;
    border-top: 8px solid #2196f3;
    border-bottom: 8px solid #2196f3;
    border-radius: 50%;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
`
