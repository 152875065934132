// transformData.js
export const implantData = [
  {
    name: 'Adaptive II 2.5 101',
    features: {
      indication: 'wrist',
      bone: 'wrist_sample1'
    },
    transformMatrix: [
      -0.9379800570175612, 0.26766469209854277, -0.22033843341805862, 0, -0.3289840037125706,
      -0.48668659877805287, 0.8092624295437775, 0, 0.10937521629043442, 0.8315598398032714,
      0.5445597257305925, 0, 6.5633249512828735, 3.0319806944446785, -18.605181558874325, 1
    ]
  },
  {
    name: 'Adaptive II 2.5 102',
    features: {
      indication: 'wrist',
      bone: 'wrist_sample1'
    },
    transformMatrix: [
      0.9532731343255134, -0.13510515244581417, 0.2702164487143688, 0, -0.30180627350640077,
      -0.4659644434423409, 0.8317392083577938, 0, 0.013539004593816129, -0.8744276615192724,
      -0.48496696807570894, 0, 5.936292782630531, 2.240434371377077, -16.873575308075864, 1
    ]
  },
  {
    name: 'Adaptive II 2.5 105',
    features: {
      indication: 'wrist',
      bone: 'wrist_sample1'
    },
    transformMatrix: [
      -0.9501602632304227, 0.09085363685604314, -0.2982299294972316, 0, -0.30598265074728537,
      -0.4550975381998881, 0.8362181821546729, 0, -0.059750243677815146, 0.8857944724338888,
      0.4602152333267666, 0, 2.502748655281522, 2.650553002400141, -15.374443580635997, 1
    ]
  },
  {
    name: 'Adaptive II 2.5 106',
    features: {
      indication: 'wrist',
      bone: 'wrist_sample1'
    },
    transformMatrix: [
      0.9312289100619595, 0.05698877817766164, 0.35995138036494895, 0, -0.27741145388420757,
      -0.5296784834458088, 0.8015507403953904, 0, 0.23633789860846494, -0.846281858092424,
      -0.4774425770131625, 0, 0.6172413928031926, 4.5278513138197845, -13.232955113488504, 1
    ]
  },
  {
    name: 'Adaptive II 2.5 107',
    features: {
      indication: 'wrist',
      bone: 'wrist_sample1'
    },
    transformMatrix: [
      -0.9358755543290256, 0.165360752691945, -0.3111153616883818, 0, -0.3504252480032954,
      -0.5285745018178507, 0.7731824762563102, 0, -0.03659361107035147, 0.8326252563411491,
      0.5526265376650561, 0, 7.783853640915051, 9.910762052467735, -25.085860771062617, 1
    ]
  },
  {
    name: 'Adaptive II 2.5 108',
    features: {
      indication: 'wrist',
      bone: 'wrist_sample1'
    },
    transformMatrix: [
      0.9538329158592622, -0.16698123833409495, 0.24963980986178821, 0, -0.29912603033112717,
      -0.45358118827804517, 0.8395163629248797, 0, -0.02695156028431675, -0.8754321056967748,
      -0.4825891023568105, 0, 12.228229617792973, 9.277912137675129, -30.796281475962772, 1
    ]
  },
  {
    name: 'MODUS straight 7028',
    features: {
      indication: 'face',
      bone: 'face_sample2'
    },
    transformMatrix: [
      -0.4555992880599786, -0.21947422308157413, 0.8627052533293047, 0, -0.5958947603067074,
      0.7951568675946584, -0.1124054738705483, 0, -0.6613159028469313, -0.5652933940174697,
      -0.4930564423288244, 0, -13.932580184525191, 19.668363770085787, -6.5005747810556995, 1
    ]
  },
  {
    name: 'MODUS straight 7028',
    features: {
      indication: 'face',
      bone: 'face_sample2'
    },
    transformMatrix: [
      -0.4555992880599786, -0.21947422308157413, 0.8627052533293047, 0, -0.5958947603067074,
      0.7951568675946584, -0.1124054738705483, 0, -0.6613159028469313, -0.5652933940174697,
      -0.4930564423288244, 0, -13.932580184525191, 19.668363770085787, -6.5005747810556995, 1
    ]
  },
  {
    name: 'MODUS straight 7029',
    features: {
      indication: 'face',
      bone: 'face_sample2'
    },
    transformMatrix: [
      0.04789298846913992, 0.022464508232044128, 0.9985998235156, 0, -0.3619233684327079,
      0.9322008487077131, -0.0036129006271516106, 0, -0.9309767650364951, -0.3612435792349973,
      0.0527763149876066, 0, -17.133441492989732, 23.50608133788217, -6.884974327726342, 1
    ]
  },
  {
    name: 'MODUS straight 7031',
    features: {
      indication: 'face',
      bone: 'face_sample2'
    },
    transformMatrix: [
      0.45001886602023544, -0.8930190479469278, -0.00001515994906148288, 0, -0.3068070284862611,
      -0.15462513200517347, 0.9391275290522654, 0, -0.8386611160041295, -0.4226204544935626,
      -0.3435687470454243, 0, -19.15965571949168, 28.603951159039674, -8.06940744160488, 1
    ]
  },
  {
    name: 'MODUS straight 7032',
    features: {
      indication: 'face',
      bone: 'face_sample2'
    },
    transformMatrix: [
      -0.5057054576905782, 0.8605192651600169, 0.06138879661980423, 0, 0.09220710946765226,
      0.12466420481989937, -0.9879052004115794, 0, -0.8577644426213897, -0.4939285680390302,
      -0.14238936283771242, 0, -6.517756493204917, 7.262314701310791, -8.273920927306408, 1
    ]
  },
  {
    name: 'TMR + 20mm',
    features: {
      indication: 'shoulder',
      bone: 'shoulder_sample_1'
    },
    transformMatrix: [
      0.8884371765821738, -0.4368467935463193, 0.140869663998303, 0, 0.16733058936398335,
      0.5940402074103769, 0.7868396951367784, 0, -0.42741064227484704, -0.6754858332697555,
      0.6008652360738361, 0, 30.524658457571956, -25.391743947617623, 5.57647772603742, 1
    ]
  },
  {
    name: 'CR Mini',
    features: {
      indication: 'shoulder',
      bone: 'shoulder_sample_1'
    },
    transformMatrix: [
      0.7663002205283922, -0.6303286060386202, 0.1243777328445386, 0, 0.48083405414930547,
      0.43425244739368396, -0.7617239817039624, 0, 0.4261250806846275, 0.64351430469105,
      0.6358512052906042, 0, 30.210438641573674, -27.087618284140557, 5.365771200615141, 1
    ]
  },
  {
    name: 'Adaptive II 2.5 101',
    features: {
      indication: 'wrist',
      bone: 'wrist_sample3'
    },
    transformMatrix: [
      -0.9801199409235055, 0.15223238108721707, 0.12724073071395262, 0, 0.03216107942446117,
      -0.5109325841016628, 0.8590189517545297, 0, 0.1957819357713009, 0.8460338034923391,
      0.4958792564464948, 0, -3.083883873897755, 6.983516122370931, -25.625726672808966, 1
    ]
  },
  {
    name: 'Adaptive II 2.5 102',
    features: {
      indication: 'wrist',
      bone: 'wrist_sample3'
    },
    transformMatrix: [
      0.9890904163094335, 0.12880124602270845, -0.07148697355343363, 0, 0.12916724702459856,
      -0.5249937260492401, 0.8412475318864372, 0, 0.07082351771070065, -0.8413036471048569,
      -0.5359031654199748, 0, -6.322212164935955, 6.783314761280913, -23.707915948011962, 1
    ]
  },
  {
    name: 'Adaptive II 2.5 105',
    features: {
      indication: 'wrist',
      bone: 'wrist_sample3'
    },
    transformMatrix: [
      -0.999985208024248, 0.003504722708462045, 0.004159405178440464, 0, 0.002005822702670197,
      -0.4732020188368051, 0.8809516593117113, 0, 0.005055730213045363, 0.8809469713254645,
      0.4731879893916414, 0, -0.5790770811788128, 6.052052494291013, -22.949440336261816, 1
    ]
  },
  {
    name: 'Adaptive II 2.5 106',
    features: {
      indication: 'wrist',
      bone: 'wrist_sample3'
    },
    transformMatrix: [
      0.999267435770867, 0.030495835351519934, -0.023121328554589315, 0, 0.03629139371612919,
      -0.5633602320476971, 0.8254139468710868, 0, 0.012146050800864401, -0.8256483833771961,
      -0.5640542708610272, 0, -2.4971980995855088, 7.593847471731573, -23.203833882481117, 1
    ]
  },
  {
    name: 'Adaptive II 2.5 107',
    features: {
      indication: 'wrist',
      bone: 'wrist_sample3'
    },
    transformMatrix: [
      -0.9791851006506738, -0.1542592504738073, 0.1319114184101925, 0, 0.1899517069215407,
      -0.4674614690409058, 0.863364421319127, 0, -0.07151844308514274, 0.8704503768770379,
      0.48703311354906853, 0, -8.067592410496145, 11.518693045140632, -32.544191865298345, 1
    ]
  },
  {
    name: 'Adaptive II 2.5 108',
    features: {
      indication: 'wrist',
      bone: 'wrist_sample3'
    },
    transformMatrix: [
      0.9812133749386656, 0.13021125317930166, -0.14235639215345963, 0, 0.1902890129458728,
      -0.5316241616836457, 0.8253277181012683, 0, 0.031786858818688264, -0.8369114530579903,
      -0.5464145087264831, 0, -7.518834378938541, 14.949791774172686, -32.451296178605304, 1
    ]
  },
  {
    name: 'Adaptive II 2.5 101',
    features: {
      indication: 'wrist',
      bone: 'wrist_sample2'
    },
    transformMatrix: [
      0.9906721786585149, 0.0776237164345128, 0.11199639761566368, 0, -0.13176643314743017,
      0.33621470112555385, 0.9325220007284859, 0, 0.03473098800200344, -0.9385809679478637,
      0.3433067507032972, 0, 3.521690603929553, 1.0203879137694454, -22.33781155156906, 1
    ]
  },
  {
    name: 'Adaptive II 2.5 102',
    features: {
      indication: 'wrist',
      bone: 'wrist_sample2'
    },
    transformMatrix: [
      -0.9597881967662221, 0.20292502112099878, -0.19397951734986624, 0, -0.12416639541069821,
      0.3128596255519204, 0.9416483212697969, 0, 0.25177236464504793, 0.927868681712318,
      -0.27508250743819906, 0, 4.179125011105439, 1.518018275312091, -18.191503551847678, 1
    ]
  },
  {
    name: 'Adaptive II 2.5 105',
    features: {
      indication: 'wrist',
      bone: 'wrist_sample2'
    },
    transformMatrix: [
      0.9815747320727712, -0.12678325902656706, 0.1429582127296986, 0, -0.12777628024634863,
      0.12075414175200605, 0.9844245321283609, 0, -0.14207134673351252, -0.9845529150230261,
      0.1023293211009787, 0, 3.894399720503681, 6.496602471328771, -22.812209240922392, 1
    ]
  },
  {
    name: 'Adaptive II 2.5 106',
    features: {
      indication: 'wrist',
      bone: 'wrist_sample2'
    },
    transformMatrix: [
      -0.8783488041422665, 0.47746215766149547, -0.023092558608316244, 0, 0.037024528461331,
      0.11611572075007726, 0.9925453761349683, 0, 0.47658426595311065, 0.8709460530916475,
      -0.11966791570849389, 0, 0.0942596392208408, 7.74286090450927, -21.441099569426072, 1
    ]
  },
  {
    name: 'Adaptive II 2.5 107',
    features: {
      indication: 'wrist',
      bone: 'wrist_sample2'
    },
    transformMatrix: [
      0.9918545494956351, -0.10481915048858728, 0.07237056263192679, 0, -0.048833975943388126,
      0.21182858480830535, 0.9760860072000168, 0, -0.11764265994260473, -0.9716694892549429,
      0.2049844096819735, 0, 4.307992861310403, -0.1703469469109571, -30.507259434102856, 1
    ]
  },
  {
    name: 'Adaptive II 2.5 108',
    features: {
      indication: 'wrist',
      bone: 'wrist_sample2'
    },
    transformMatrix: [
      -0.9876828238197861, 0.12812349925435132, -0.08981652670969636, 0, -0.04053218241953068,
      0.3449245788766423, 0.9377548597981675, 0, 0.15112836173161956, 0.9298448278210678,
      -0.3354829570257267, 0, 2.928492457029833, -2.736941274717216, -29.774085453437767, 1
    ]
  }
]
