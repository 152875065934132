/* eslint-disable react/prop-types */
import React, { useEffect, useContext, useRef } from 'react'
import { Box, useTheme, Typography, Grid, Button, FormControlLabel } from '@mui/material'
import { Checkbox } from '@mui/material'
import Switch from '@mui/material/Switch'
import { colorMapArray } from '../../renderComponents/ColorMap'
import { UserContext } from '../../../../App'

import Draggable from 'react-draggable' // add drag function to move box
import OpenWithIcon from '@mui/icons-material/OpenWith'
import { debounce } from 'lodash'
import CloseButton from './CloseButton'

import { indicationMap } from '../../../caseOverview/createCase/options'

// label box
const MainViewLabelBox = ({
  label: label,
  setSelectedSegment: setSelectedSegment,
  originalLabelID: originalLabelID,
  setOriginalLabelID: setOriginalLabelID,
  buttonState: buttonState,
  filePath: filePath,
  setShowLabelBox: setShowLabelBox,
  indication: indication,
  labelColorSwitch: labelColorSwitch,
  setLabelColorSwitch: setLabelColorSwitch
}) => {
  const theme = useTheme()
  const draggableRef = useRef(null)
  const scrollableRef = useRef(null)

  const {
    uniqueValues,
    setUniqueValues,
    selectedValue,
    setSelectedValue,
    checkedValues,
    setCheckedValues
  } = useContext(UserContext)

  function getColorForLabel(label) {
    const startIndex = label * 3
    const r = colorMapArray[startIndex]
    const g = colorMapArray[startIndex + 1]
    const b = colorMapArray[startIndex + 2]
    return `rgb(${r},${g},${b})`
  }

  const handleCheckboxChange = (e, value) => {
    e.stopPropagation() // Prevent propagation
    const isChecked = checkedValues[value]

    setCheckedValues((prev) => ({
      ...prev,
      [value]: !prev[value]
    }))

    if (buttonState !== 'Rep' && buttonState !== 'Target' && buttonState !== 'Implant') {
      // If checkbox is unchecked, set values to negative, else revert them
      if (isChecked) {
        for (let i = 0; i < label.data.length; i++) {
          if (label.data[i] === value) {
            label.data[i] = -1 * value
          }
        }
      } else {
        for (let i = 0; i < label.data.length; i++) {
          if (label.data[i] === -1 * value) {
            label.data[i] = value // This assumes that only the value we unchecked got replaced with negative value
          }
        }
      }
    }
  }

  const handleListItemClick = (value) => {
    setSelectedValue(value)
    setSelectedSegment(value)
  }

  const handleSwitch = () => {
    setLabelColorSwitch(!labelColorSwitch)
  }

  // Scroll to bottom whenever uniqueValues changes or component mounts
  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight
    }
  }, [uniqueValues])

  // debouncing waits until there's a pause in clicks for a certain duration before processing
  const handleAddButton = debounce(() => {
    let newLabel

    if (uniqueValues.length === 0) {
      newLabel = 1
    } else {
      newLabel = Math.max(...uniqueValues) + 1
    }

    setUniqueValues((prev) => [...prev, newLabel])

    setCheckedValues((prev) => ({ ...prev, [newLabel]: true }))
  }, 300)

  const handleRemoveButton = debounce(() => {
    if (selectedValue !== null) {
      // Create a new array without the selected value
      const updatedUniqueValues = uniqueValues.filter((val) => val !== selectedValue)

      setUniqueValues(updatedUniqueValues)

      // Update checkedValues to remove the key associated with the selected value
      setCheckedValues((prev) => {
        const updated = { ...prev }
        delete updated[selectedValue]
        return updated
      })

      if (buttonState !== 'Rep' && buttonState !== 'Target' && buttonState !== 'Target') {
        // remove current label data
        for (let i = 0; i < label.data.length; i++) {
          if (label.data[i] === selectedValue) {
            label.data[i] = 0
          }
        }
      }

      // Update the selectedValue
      if (updatedUniqueValues.length > 0) {
        const currentIndex = uniqueValues.indexOf(selectedValue)
        const previousIndex = currentIndex - 1

        if (previousIndex >= 0) {
          setSelectedValue(updatedUniqueValues[previousIndex])
          setSelectedSegment(updatedUniqueValues[previousIndex])
        } else {
          setSelectedValue(updatedUniqueValues[0]) // defaulting to the first item if we removed the first item
          setSelectedSegment(updatedUniqueValues[0])
        }
      } else {
        setSelectedValue(null) // If no more values, then set to null
        setSelectedSegment(null)
      }
    } else {
      console.log('No label selected for removal')
    }
  }, 300)

  // remove additional tool box when view setting is expanded
  useEffect(() => {
    if (
      label !== null &&
      buttonState !== 'Rep' &&
      buttonState !== 'Target' &&
      buttonState !== 'Target'
    ) {
      if (originalLabelID !== label.id) {
        setOriginalLabelID(label.id)

        const uniqueVals = [...new Set(label.data.map((val) => (val < 0 ? -1 * val : val)))].filter(
          (val) => val !== 0
        )

        // Initialize the checkedValues state
        const initialCheckedState = uniqueVals.reduce((acc, currVal) => {
          acc[currVal] = true // Setting all as checked by default
          return acc
        }, {})

        setUniqueValues(uniqueVals)
        setCheckedValues(initialCheckedState)

        if (Object.keys(uniqueVals).length !== 0) {
          setSelectedValue(uniqueVals[0])
          setSelectedSegment(uniqueVals[0])
        }
      } else {
        if (Object.keys(uniqueValues).length !== 0) {
          setSelectedValue(uniqueValues[0])
          setSelectedSegment(uniqueValues[0])
        } else {
          // Extracting unique values from the data excluding 0
          const uniqueVals = [
            ...new Set(label.data.map((val) => (val < 0 ? -1 * val : val)))
          ].filter((val) => val !== 0)

          // Initialize the checkedValues state
          const initialCheckedState = uniqueVals.reduce((acc, currVal) => {
            acc[currVal] = true // Setting all as checked by default
            return acc
          }, {})

          setUniqueValues(uniqueVals)
          setCheckedValues(initialCheckedState)

          if (Object.keys(uniqueVals).length !== 0) {
            setSelectedValue(uniqueVals[0])
            setSelectedSegment(uniqueVals[0])
          }
        }
      }
    }
  }, [label])

  useEffect(() => {
    const transformKeysToLowerCase = (obj) => {
      return Object.keys(obj).reduce((acc, key) => {
        const lowerCaseKey = key.toLowerCase()
        acc[lowerCaseKey] = obj[key]
        return acc
      }, {})
    }

    if (buttonState === 'Rep') {
      let allUniqueVals = []

      for (let key in filePath.repositionObjects) {
        if (Object.hasOwnProperty.call(filePath.repositionObjects, key)) {
          let matches = key.match(/label(\d+)/)
          if (matches && matches.length > 1) {
            // Convert the string match to an integer
            let matchNumber = parseInt(matches[1], 10) // 10 is the radix for decimal
            if (!isNaN(matchNumber)) {
              allUniqueVals.push(matchNumber)
            }
          }
        }
      }

      // Remove duplicates just in case
      allUniqueVals = [...new Set(allUniqueVals)].sort((a, b) => a - b)

      // Initialize the checkedValues state for all unique values
      const initialCheckedState = allUniqueVals.reduce((acc, currVal) => {
        acc[currVal] = false // Setting all as checked by default
        return acc
      }, {})

      const indicationMapLowerCase = transformKeysToLowerCase(indicationMap)

      Object.keys(indicationMapLowerCase).forEach((key) => {
        indicationMapLowerCase[key] = transformKeysToLowerCase(indicationMapLowerCase[key])
      })

      // Ensure both indication and side are checked in a case-insensitive manner
      const indicationLower = indication.toLowerCase()
      const sideLower = filePath.side.toLowerCase()
      const relevantIndicationMap =
        indicationMapLowerCase[indicationLower] || indicationMapLowerCase.default
      const relevantValues = relevantIndicationMap[sideLower] || []

      // Set the values based on indication and side
      allUniqueVals.forEach((val) => {
        if (relevantValues.includes(val) || val > 3) {
          initialCheckedState[val] = true
        }
      })

      // Additional check: Set the last item in initialCheckedState to true if indicationLower is 'face'
      if (indicationLower === 'face') {
        const lastIndex = Object.keys(initialCheckedState).length
        initialCheckedState[lastIndex] = true
      }

      if (indicationLower === 'clavicle') {
        Object.keys(initialCheckedState).forEach((key) => {
          initialCheckedState[key] = true
        })
      }

      if (indicationLower === 'elbow') {
        initialCheckedState[1] = true
      }

      if (filePath.filename === 'wrist_sample3') {
        initialCheckedState[2] = true
      }

      // Now update the state with the consolidated unique values
      setUniqueValues(allUniqueVals)
      setCheckedValues(initialCheckedState)

      // Set the selected value and segment if there are any unique values
      if (allUniqueVals.length > 0) {
        setSelectedValue(allUniqueVals[0])
        setSelectedSegment(allUniqueVals[0])
      }

      setOriginalLabelID('Rep')
    } else if (buttonState === 'Target' || buttonState === 'Implant') {
      let allUniqueVals = []

      for (let key in filePath.targetObjects) {
        if (Object.hasOwnProperty.call(filePath.targetObjects, key)) {
          let matches = key.match(/label(\d+)/)
          if (matches && matches.length > 1) {
            // Convert the string match to an integer
            let matchNumber = parseInt(matches[1], 10) // 10 is the radix for decimal
            if (!isNaN(matchNumber)) {
              allUniqueVals.push(matchNumber)
            }
          }
        }
      }

      // Remove duplicates just in case
      allUniqueVals = [...new Set(allUniqueVals)].sort((a, b) => a - b)

      // Initialize the checkedValues state for all unique values
      const initialCheckedState = allUniqueVals.reduce((acc, currVal) => {
        acc[currVal] = true // Setting all as checked by default
        return acc
      }, {})

      // Now update the state with the consolidated unique values
      setUniqueValues(allUniqueVals)
      setCheckedValues(initialCheckedState)

      // Set the selected value and segment if there are any unique values
      if (allUniqueVals.length > 0) {
        setSelectedValue(allUniqueVals[0])
        setSelectedSegment(allUniqueVals[0])
      }

      setOriginalLabelID('Rep')
    }
  }, [buttonState])

  return (
    // dragging is only working at the first line of this box
    <Draggable nodeRef={draggableRef} handle=".drag-icon">
      <Box
        ref={draggableRef}
        sx={{
          position: 'absolute',
          top: `calc(${theme.sizes.nav.top} + 100px)`,
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '16px',
          gap: '16px',
          width: '250px',
          background: '#232323',
          boxShadow: '0px 4px 20px rgba(255, 255, 255, 0.25)',
          borderRadius: '8px',
          zIndex: 9990,
          right: '10px',
          maxHeight: '30%',
          overflowY: 'auto'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              color: '#FFFFFF',
              fontFamily: 'Open Sans',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '20px',
              flex: 'none',
              order: 0,
              flexGrow: 0
            }}
          >
            Label Box
          </Typography>

          <div>
            <OpenWithIcon
              className="drag-icon"
              sx={{
                color: '#FFFFFF',
                cursor: 'move',
                backgroundColor: '',
                borderRadius: '50%',
                fontSize: '25px',
                zIndex: 9999
              }}
            />
            <CloseButton setShowToolBox={setShowLabelBox} />
          </div>
        </Box>

        <Box display="flex" alignItems="center">
          <span
            style={{
              color: '#FFFFFF',
              fontSize: '16px',
              fontWeight: 300,
              lineHeight: '20px',
              flex: 'none',
              marginLeft: '5px',
              marginRight: '10px',
              order: 0,
              flexGrow: 0
            }}
          >
            Neutral Mode
          </span>
          <FormControlLabel
            control={<Switch checked={labelColorSwitch} onChange={handleSwitch} />}
            label=""
          />
          <Box flexGrow={1} textAlign="right">
            <span
              style={{
                color: '#FFFFFF',
                marginLeft: '-100px',
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '20px',
                // flex: 'none',
                order: 2,
                flexGrow: 0
              }}
            >
              Color Labels
            </span>
          </Box>
        </Box>

        <Box>
          {buttonState !== 'Rep' && buttonState !== 'Target' && buttonState !== 'Implant' && (
            <>
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  marginRight: '10px',
                  color: '#FFFFFF',
                  borderColor: '#FFFFFF',
                  '&:hover': {
                    background: '#91C58E'
                  },
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '20px'
                }}
                onClick={handleAddButton}
              >
                Add
              </Button>
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  marginRight: '10px',
                  color: '#FFFFFF',
                  borderColor: '#FFFFFF',
                  '&:hover': {
                    background: '#91C58E'
                  },
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '20px'
                }}
                onClick={handleRemoveButton}
              >
                Remove
              </Button>
            </>
          )}
        </Box>

        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{ marginTop: '2px', maxHeight: '200px', overflowY: 'auto' }}
          ref={scrollableRef}
        >
          <Grid item>
            {uniqueValues
              .sort((a, b) => a - b)
              .map((value, index) => (
                <Grid item key={index}>
                  <Box
                    onClick={() => handleListItemClick(value)}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: selectedValue === value ? '#333' : 'transparent',
                      padding: '5px',
                      borderRadius: '5px'
                    }}
                  >
                    <Checkbox
                      color="primary"
                      onChange={(e) => handleCheckboxChange(e, value)}
                      checked={checkedValues[value]}
                    />
                    {labelColorSwitch && (
                      <Box
                        style={{
                          width: '16px',
                          height: '16px',
                          backgroundColor: getColorForLabel(value),
                          margin: '0 8px'
                        }}
                      />
                    )}
                    <Typography
                      variant="body1"
                      style={{
                        color: '#FFFFFF',
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '20px'
                      }}
                    >
                      {indication === 'Clavicle'
                        ? `Clavicle piece ${value}`
                        : indication === 'Wrist'
                        ? value === 1
                          ? 'Radius'
                          : value === 2
                          ? 'Ulna'
                          : value === 3
                          ? 'Hand'
                          : `Broken piece ${value - 3}`
                        : indication === 'Elbow'
                        ? value === 1
                          ? 'Radius'
                          : value === 2
                          ? 'Ulna'
                          : value === 3
                          ? 'Humerus'
                          : `Broken piece ${value - 3}`
                        : indication === 'Shoulder'
                        ? value === 1
                          ? 'Scapula'
                          : value === 2
                          ? 'Humerus'
                          : `Broken piece ${value - 2}`
                        : indication === 'Calcaneus'
                        ? value === 1
                          ? 'Calcaneus'
                          : value === 2
                          ? 'Tibia'
                          : value === 3
                          ? 'Fibula'
                          : value === 4
                          ? 'Foot Bones'
                          : value === 5
                          ? 'Talus'
                          : `Bone piece ${value - 5}`
                        : indication === 'Face'
                        ? value === 1
                          ? 'Right Cheekbone'
                          : value === 2
                          ? 'Left Cheekbone'
                          : value === 3
                          ? 'Skull'
                          : `Bone piece ${value - 3}`
                        : ''}
                    </Typography>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Box>
    </Draggable>
  )
}

export default MainViewLabelBox
