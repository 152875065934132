/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useContext, useCallback } from 'react'
import { UserContext } from '../../../App'
import * as THREE from 'three'
import { update2DTexture, assign2DTexture } from './LabelHandler'
import { removeAllObjects } from './SegmentToolHandler'
import {
  boundCircleCursor,
  boundHandleMouseWheel,
  boundOnPointerRelease,
  boundOnPointerClick
} from './MouseHandler'

import requestAnimationFrame from 'dat.gui/src/dat/utils/requestAnimationFrame'

const RenderingSagittalView = ({
  mainGUIRef: mainGUIRef,
  buttonState: buttonState,
  volume: volume,
  label: label,
  viewBoxRef: viewBoxRef,
  currentView: currentView,
  volConfig: volConfig,
  measureConfig: measureConfig,
  segmentConfig: segmentConfig,
  Radius: Radius,
  selectedSegment: selectedSegment,
  setLabel: setLabel,
  slider: slider,
  setslider: setslider,
  axis: axis,
  sliderType: sliderType,
  rASDimensionsIndex: rASDimensionsIndex,
  view: view,
  setCurrentView: setCurrentView,
  brightness: brightness,
  contrast: contrast,
  labelThresholdRange: labelThresholdRange,
  isSegmentationVisible: isSegmentationVisible,
  IsLabelChange: IsLabelChange,
  labelColorSwitch: labelColorSwitch,
  isRestore: isRestore,
  setIsRestore: setIsRestore,
  predictedData: predictedData,
  crosshairsPositionX: crosshairsPositionX,
  crosshairsPositionY: crosshairsPositionY,
  crosshairsOn: crosshairsOn,
  sliderSagittal: sliderSagittal,
  setsliderSagittal: setsliderSagittal,
  setVolumeDimensions: setVolumeDimensions,
  volumeDimensions: volumeDimensions,
  volume2D: volume2D,
  SetIsIntensityChange: SetIsIntensityChange,
  isIntensityChange: isIntensityChange,
  sagittalViewLoaded: sagittalViewLoaded,
  setSagittalViewLoaded: setSagittalViewLoaded,
  axialViewBoxRef: axialViewBoxRef
}) => {
  const containerRef = useRef(null)
  const rendererRef = useRef(null)
  const scene = useRef(null)
  const cameraRef = useRef(null)
  const geometryRef = useRef(null)

  const labelRef = useRef(null)

  const sliceRef = useRef(null)
  const sliceSegRef = useRef(null)

  const [segmenting, setSegmenting] = useState(false)
  const [wheel, setWheel] = useState(-1)
  const nearPlane = 0.1
  const farPlane = 1000

  const meshSegRef = useRef(null)
  const meshRef = useRef(null)
  const lineXRef = useRef(null)
  const lineYRef = useRef(null)
  const { setsliderAxial, sliderAxial, setsliderCoronal, sliderCoronal, setIsSegmentToolAffected } =
    useContext(UserContext)
  let mousePoints = []
  const [volumeWidth, setVolumeWidth] = useState(0)
  const [volumeHeight, setVolumeHeight] = useState(0)
  const [clickedPoint, setClickedPoint] = useState([])

  const [wholeWidth, setWholeWidth] = useState(0)
  const [wholeHeight, setWholeHeight] = useState(0)

  let existingController
  let measureDots = {}

  let curvePointList = []
  let measureDots3D = {}

  /**
   * adjustBrightnessContrast.
   * @param {mesh} THERE.mesh - There js mesh.
   * @returns {adjustedColor}
   */
  const adjustBrightnessContrast = (brightness, contrast) => {
    // Apply brightness adjustment
    let adjustedColor = new THREE.Color(
      brightness.brightness,
      brightness.brightness,
      brightness.brightness
    )

    if (contrast.contrast > 0) {
      // Apply contrast adjustment
      adjustedColor.r = (adjustedColor.r - 0.5) * contrast.contrast + 0.5
      adjustedColor.g = (adjustedColor.g - 0.5) * contrast.contrast + 0.5
      adjustedColor.b = (adjustedColor.b - 0.5) * contrast.contrast + 0.5
    }
    // Update the material's color with the adjusted brightness and contrast
    return adjustedColor
  }

  // The main animation function that re-renders the scene each animation frame
  function tick() {
    requestAnimationFrame(tick)
    rendererRef.current.render(scene.current, cameraRef.current)
  }

  const renderLoopRef = useRef(null)
  function animate() {
    if (renderLoopRef.current === null) {
      renderLoopRef.current = requestAnimationFrame(tick)
      console.debug('render loop initiated')
    } else {
      console.debug('render loop already initiated, ignoring')
    }
  }

  const updateSize = () => {
    if (
      currentView === 'whole' ||
      (viewBoxRef.current && viewBoxRef.current.getAttribute('name').toLowerCase() === currentView)
    ) {
      setTimeout(() => {
        if (viewBoxRef.current) {
          // Check if viewBoxRef.current is not null
          //   rendererRef.current.setSize(
          //     viewBoxRef.current.clientWidth,
          //     viewBoxRef.current.clientHeight
          //   )

          if (currentView === 'whole') {
            if (
              wholeWidth !== viewBoxRef.current.clientWidth ||
              wholeHeight !== viewBoxRef.current.clientHeight
            ) {
              setWholeWidth(viewBoxRef.current.clientWidth)
              setWholeHeight(viewBoxRef.current.clientHeight)
              reinitializeScene(viewBoxRef.current.clientWidth, viewBoxRef.current.clientHeight)
            }
            if (
              wholeWidth === viewBoxRef.current.clientWidth &&
              wholeHeight === viewBoxRef.current.clientHeight
            ) {
              reinitializeScene(wholeWidth, wholeHeight)
            }
          } else {
            reinitializeScene(viewBoxRef.current.clientWidth, viewBoxRef.current.clientHeight)
          }
        }
      }, 5000)
    }

    if (rendererRef.current) {
      // It's also a good practice to check if rendererRef.current is not null
      rendererRef.current.setPixelRatio(window.devicePixelRatio)
    }
  }

  function createLine(start, end, color = 0xff0000) {
    const material = new THREE.MeshBasicMaterial({
      color: color,
      emissive: color,
      depthTest: false
    })
    material.depthWrite = false
    const points = []
    points.push(new THREE.Vector3(start.x, start.y, start.z))
    points.push(new THREE.Vector3(end.x, end.y, end.z))

    const geometry = new THREE.BufferGeometry().setFromPoints(points)
    const line = new THREE.Line(geometry, material)

    return line
  }

  const reinitializeScene = (width, height) => {
    // Reinitialize the camera
    const camera = new THREE.OrthographicCamera(
      -window.innerWidth / 4,
      window.innerWidth / 4,
      window.innerHeight / 4,
      -window.innerHeight / 4,
      nearPlane,
      farPlane
    )

    camera.position.set(0, 0, 5)
    camera.up.set(0, 0, -5)
    cameraRef.current = camera

    // Remove the old camera and add the new one
    if (scene.current) {
      const oldCamera = scene.current.getObjectByName('camera')
      if (oldCamera) {
        scene.current.remove(oldCamera)
      }
      scene.current.add(camera)
    }

    // Reinitialize the renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true })
    renderer.sortObjects = false
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(width, height)

    // Replace the old renderer with the new one
    if (containerRef.current) {
      if (
        rendererRef.current &&
        rendererRef.current.domElement.parentNode === containerRef.current
      ) {
        containerRef.current.removeChild(rendererRef.current.domElement)
      }
      containerRef.current.appendChild(renderer.domElement)
    }
    rendererRef.current = renderer

    if (volume === null) {
      return
    }

    // Adjust geometry size based on currentView
    let volumeWidth, volumeHeight

    if (axis === 'x') {
      volumeWidth = volume.RASDimensions[2]
      volumeHeight = volume.RASDimensions[1]
    } else if (axis === 'y') {
      volumeWidth = volume.RASDimensions[0]
      volumeHeight = volume.RASDimensions[2]
    } else {
      volumeWidth = volume.RASDimensions[0]
      volumeHeight = volume.RASDimensions[1]
    }

    const volumeAspectRatio = volumeWidth / volumeHeight

    let geoHeight
    if (currentView === 'whole') {
      geoHeight = height * 1.2
    } else {
      geoHeight = height * 0.65
    }

    let geoWidth = geoHeight * volumeAspectRatio

    if (volumeAspectRatio > 3) {
      if (currentView === 'whole') {
        geoWidth = width * 0.6
      } else {
        geoWidth = width * 0.3
      }
    } else if (volumeAspectRatio < 0.1) {
      if (currentView === 'whole') {
        geoWidth = width * 0.5
      } else {
        geoWidth = width * 0.25
      }
    } else {
      let maxWidth, minWidth
      if (currentView === 'whole') {
        maxWidth = width * 0.9
        minWidth = width * 0.7
      } else {
        maxWidth = width * 0.5
        minWidth = width * 0.4
      }
      geoWidth = Math.min(Math.max(geoWidth, minWidth), maxWidth)
    }

    if (axis === 'x') {
      setVolumeDimensions((prevPosition) => ({
        ...prevPosition,
        x: {
          width: geoWidth,
          height: geoHeight
        }
      }))
    } else if (axis === 'y') {
      setVolumeDimensions((prevPosition) => ({
        ...prevPosition,
        y: {
          width: geoWidth,
          height: geoHeight
        }
      }))
    } else {
      setVolumeDimensions((prevPosition) => ({
        ...prevPosition,
        z: {
          width: geoWidth,
          height: geoHeight
        }
      }))
    }
    setVolumeWidth(geoWidth)
    setVolumeHeight(geoHeight)

    // Update the geometry of mesh and meshSeg if they are available
    if (geometryRef.current) {
      const newGeometry = new THREE.PlaneGeometry(geoWidth, geoHeight)
      geometryRef.current = newGeometry

      if (meshRef.current) {
        meshRef.current.geometry.dispose()
        meshRef.current.geometry = newGeometry
      }

      if (meshSegRef.current) {
        meshSegRef.current.geometry.dispose()
        meshSegRef.current.geometry = newGeometry
      }

      scene.current.children.forEach((child) => {
        if (child.name === 'mesh_' + axis) {
          child.geometry.dispose()
          child.geometry = newGeometry
        } else if (child.name === 'meshSeg_' + axis) {
          child.geometry.dispose()
          child.geometry = newGeometry
        }
      })
    }

    // Trigger a render to apply the changes
    renderer.render(scene.current, camera)

    animate()
  }

  useEffect(() => {
    if (scene.current) {
      scene.current.clear()
    }

    if (containerRef.current) {
      if (
        rendererRef.current &&
        rendererRef.current.domElement.parentNode === containerRef.current
      ) {
        containerRef.current.removeChild(rendererRef.current.domElement)
        containerRef.current.innerHTML = ''
      }
    }
  }, [buttonState])

  useEffect(() => {
    if (currentView !== 'view3' && currentView !== 'whole') {
      return
    }

    if (volume === null) {
      return
    }
    if (!viewBoxRef.current) {
      return
    }
    if (!mainGUIRef.current) {
      return
    }
    if (sagittalViewLoaded) {
      return
    }

    mainGUIRef.current.hide()

    let meshSeg
    let mesh
    let geometry

    let renderer
    let light = new THREE.DirectionalLight(0xffffff, 0)

    let slice, sliceSeg

    const guiFolder = mainGUIRef.current.__folders['Views']
    if (!guiFolder) {
      return
    }

    const timeoutId = setTimeout(() => {
      scene.current = new THREE.Scene()

      const camera = new THREE.OrthographicCamera(
        -window.innerWidth / 4,
        window.innerWidth / 4,
        window.innerHeight / 4,
        -window.innerHeight / 4,
        nearPlane,
        farPlane
      ) // Denominator (eg. 4) defines the size of the scan

      let width = viewBoxRef.current.clientWidth
      let height = viewBoxRef.current.clientHeight

      camera.position.set(0, 0, 5)
      camera.up.set(0, 0, -5)
      cameraRef.current = camera

      scene.current.add(camera)

      renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true })
      renderer.sortObjects = false
      renderer.setPixelRatio(window.devicePixelRatio)
      renderer.setSize(width, height)
      containerRef.current.appendChild(renderer.domElement)
      rendererRef.current = renderer

      if (buttonState === 'Image') {
        if (slider.current !== -1) {
          setslider((prevSlider) => ({
            ...slider,
            current: prevSlider.current || Math.floor(volume.RASDimensions[rASDimensionsIndex] / 2),
            max: volume.RASDimensions[rASDimensionsIndex] - 1
          }))
        } else {
          setslider({
            ...slider,
            current: Math.floor(volume.RASDimensions[rASDimensionsIndex] / 2),
            max: volume.RASDimensions[rASDimensionsIndex] - 1
          })
        }
      } else {
        setslider((prevSlider) => ({
          ...slider,
          current: prevSlider.current || Math.floor(volume.RASDimensions[rASDimensionsIndex] / 2),
          max: volume.RASDimensions[rASDimensionsIndex] - 1
        }))
      }

      let volumeWidth, volumeHeight

      if (axis === 'x') {
        volumeWidth = volume.RASDimensions[2]
        volumeHeight = volume.RASDimensions[1]
      } else if (axis === 'y') {
        volumeWidth = volume.RASDimensions[0]
        volumeHeight = volume.RASDimensions[2]
      } else {
        volumeWidth = volume.RASDimensions[0]
        volumeHeight = volume.RASDimensions[1]
      }

      // Aspect ratio of the volume
      const volumeAspectRatio = volumeWidth / volumeHeight

      // Set geometry height to 120% of component height

      let geoHeight

      if (currentView === 'whole') {
        geoHeight = height * 1.2
      } else {
        geoHeight = height * 0.65
      }

      // Calculate geometry width based on aspect ratio
      let geoWidth = geoHeight * volumeAspectRatio

      // Check if volumeAspectRatio is more than 3
      if (volumeAspectRatio > 3) {
        if (currentView === 'whole') {
          geoWidth = width * 0.6 // Fixed width for 'whole' view
        } else {
          geoWidth = width * 0.3 // Fixed width for non-'whole' view
        }
      } else if (volumeAspectRatio < 0.1) {
        if (currentView === 'whole') {
          geoWidth = width * 0.5 // Fixed width for 'whole' view
        } else {
          geoWidth = width * 0.25 // Fixed width for non-'whole' view
        }
      } else {
        // Apply constraints to geometry width
        let maxWidth, minWidth
        if (currentView === 'whole') {
          maxWidth = width * 0.9 // Maximum: 90% of component width
          minWidth = width * 0.7 // Minimum: 70% of component width
        } else {
          maxWidth = width * 0.5 // Maximum: 60% of component width
          minWidth = width * 0.4 // Minimum: 50% of component width
        }
        geoWidth = Math.min(Math.max(geoWidth, minWidth), maxWidth)
      }
      if (axis === 'x') {
        setVolumeDimensions((prevPosition) => ({
          ...prevPosition,
          x: {
            width: geoWidth,
            height: geoHeight
          }
        }))
      } else if (axis === 'y') {
        setVolumeDimensions((prevPosition) => ({
          ...prevPosition,
          y: {
            width: geoWidth,
            height: geoHeight
          }
        }))
      } else {
        setVolumeDimensions((prevPosition) => ({
          ...prevPosition,
          z: {
            width: geoWidth,
            height: geoHeight
          }
        }))
      }
      setVolumeWidth(geoWidth)
      setVolumeHeight(geoHeight)
      // Create new geometry with calculated dimensions
      geometry = new THREE.PlaneGeometry(geoWidth, geoHeight)
      geometryRef.current = geometry

      // Label part
      if (buttonState !== 'Image' && buttonState !== 'None') {
        if (label !== null) {
          labelRef.current = label

          const assign2DTextureResult = assign2DTexture(labelRef.current, axis, slider.current)

          sliceSeg = assign2DTextureResult.sliceSeg
          const material = assign2DTextureResult.material

          sliceSegRef.current = sliceSeg

          meshSeg = new THREE.Mesh(geometry, material)

          // Set the mesh name based on the axis
          meshSeg.name = 'meshSeg_' + axis

          meshSegRef.current = meshSeg

          if (axis === 'x') {
            meshSeg.scale.x = -1 // flip left to right
          }

          meshSeg.visible = isSegmentationVisible

          scene.current.add(meshSeg)
        }
      }
      slice = volume.extractSlice(axis, slider.current)
      sliceRef.current = slice

      slice.mesh.material.transparent = true
      slice.mesh.material.opacity = 0.8
      // console.log(slice.mesh.material)
      if (axis === 'z' || axis === 'x') {
        slice.mesh.material.map.flipY = false //unique
      }

      mesh = new THREE.Mesh(geometry, slice.mesh.material)
      mesh.name = 'mesh_' + axis
      // console.log('sd', brightness)
      mesh.material.color = adjustBrightnessContrast(brightness, contrast)

      meshRef.current = mesh

      if (axis === 'x') {
        mesh.scale.x = -1 // flip left to right
      }

      scene.current.add(mesh)

      existingController = guiFolder.__controllers.find(
        (controller) => controller.property === sliderType
      )
      if (existingController) {
        guiFolder.remove(existingController)
      }

      guiFolder
        .add(volConfig, sliderType, 0, volume.RASDimensions[rASDimensionsIndex] - 1, 1)
        .name(sliderType)
        .onChange(function () {
          sliceRef.current.index = volConfig[sliderType]
          sliceRef.current.repaint.call(sliceRef.current)

          if (labelRef.current != null) {
            sliceSegRef.current.index = volConfig[sliderType]
            sliceSegRef.current.repaint.call(sliceSegRef.current)
            update2DTexture(
              labelRef.current,
              sliceSegRef.current,
              meshSegRef.current,
              labelColorSwitch
            )
          }
        })

      guiFolder.hide()

      light.position.set(0, 1, 0)
      scene.current.add(light)
      const handleMouseWheel = (event) =>
        boundHandleMouseWheel(event, view, volume, slice, setWheel)

      removeAllObjects(scene.current, 'circle')
      removeAllObjects(scene.current, 'line')
      removeAllObjects(scene.current, 'measure')
      removeAllObjects(scene.current, 'arc')

      containerRef.current.addEventListener('wheel', handleMouseWheel)

      const minMax = volume.computeMinMax()
      const min = minMax[0]
      const max = minMax[1]
      // // attach the scalar range to the volume
      volume.windowLow = min
      volume.windowHigh = max
      animate()

      setSagittalViewLoaded(true)

      if (wholeWidth === 0 || wholeHeight === 0) {
        setWholeWidth(axialViewBoxRef.current.clientWidth)
        setWholeHeight(axialViewBoxRef.current.clientHeight)
      }

      return () => {
        // Clean up the animation loop when component unmounts
        if (containerRef.current) {
          if (
            rendererRef.current &&
            rendererRef.current.domElement.parentNode === containerRef.current
          ) {
            containerRef.current.removeChild(rendererRef.current.domElement)
          }
          renderer.dispose()

          containerRef.current.removeEventListener('wheel', handleMouseWheel)
        }
      }
    }, 1000) // 3-second delay

    return () => clearTimeout(timeoutId)
  }, [mainGUIRef, volume, label, currentView])

  useEffect(() => {
    if (volume === null) {
      return
    }

    if (!viewBoxRef.current) {
      return
    }

    if (!sagittalViewLoaded) {
      return
    }

    if (currentView !== 'whole' && currentView !== 'view3' && currentView !== 'dual Sagittal') {
      return
    }

    let width, height

    if (currentView === 'whole') {
      width = wholeWidth
      height = wholeHeight
    } else {
      width = viewBoxRef.current.clientWidth
      height = viewBoxRef.current.clientHeight
    }

    reinitializeScene(width, height)
  }, [currentView])

  useEffect(() => {
    if (volume === null) {
      return
    }

    const handleMouseClick = (event) => {
      if (!crosshairsOn) return
      let bbox = event.currentTarget.getBoundingClientRect()
      let pointer = new THREE.Vector3()
      let raycaster = new THREE.Raycaster()

      pointer.set(
        ((event.clientX - bbox.x) / event.currentTarget.clientWidth) * 2 - 1,
        -((event.clientY - bbox.y) / event.currentTarget.clientHeight) * 2 + 1,
        0
      )

      raycaster.setFromCamera(pointer, cameraRef.current)
      const intersects = raycaster.intersectObjects(scene.current.children)

      if (axis === 'x' && volumeDimensions.x !== null && intersects.length > 0) {
        setsliderAxial((prevPosition) => ({
          ...prevPosition,
          current:
            Math.floor(sliderAxial.max / 2) -
            ((intersects[0].point.y * 2) / volumeDimensions.x.height) * (sliderAxial.max / 2)
        }))
        setsliderCoronal((prevPosition) => ({
          ...prevPosition,
          current:
            ((intersects[0].point.x * 2) / volumeDimensions.x.width) * (sliderCoronal.max / 2) +
            Math.floor(sliderCoronal.max / 2)
        }))
      } else if (axis === 'y' && volumeDimensions.y !== null && intersects.length > 0) {
        setsliderSagittal((prevPosition) => ({
          ...prevPosition,
          current:
            ((intersects[0].point.x * 2) / volumeDimensions.y.width) * (sliderSagittal.max / 2) +
            Math.floor(sliderSagittal.max / 2)
        }))

        setsliderCoronal((prevPosition) => ({
          ...prevPosition,
          current:
            Math.floor(sliderCoronal.max / 2) -
            ((intersects[0].point.y * 2) / volumeDimensions.y.height) * (sliderCoronal.max / 2)
        }))
      } else if (axis === 'z' && volumeDimensions.z !== null && intersects.length > 0) {
        setsliderSagittal((prevPosition) => ({
          ...prevPosition,
          current:
            ((intersects[0].point.x * 2) / volumeDimensions.z.width) * (sliderSagittal.max / 2) +
            Math.floor(sliderSagittal.max / 2)
        }))

        setsliderAxial((prevPosition) => ({
          ...prevPosition,
          current:
            Math.floor(sliderAxial.max / 2) -
            ((intersects[0].point.y * 2) / volumeDimensions.z.height) * (sliderAxial.max / 2)
        }))
      }
    }

    if (containerRef.current) {
      containerRef.current.addEventListener('click', handleMouseClick)
    }

    return () => {
      if (containerRef.current) {
        if (handleMouseClick) {
          containerRef.current.removeEventListener('click', handleMouseClick)
        }
      }
    }
  }, [volume, volumeDimensions, crosshairsOn])

  useEffect(() => {
    if (!crosshairsOn && scene.current) {
      scene.current.remove(lineXRef.current)
      scene.current.remove(lineYRef.current)
      return
    }
    if (!crosshairsPositionX || !scene.current) return

    scene.current.remove(lineXRef.current)
    scene.current.remove(lineYRef.current)
    let colorX = 0xff0000 // Red
    let colorY = 0x00ff00 // Green
    switch (axis) {
      case 'x':
        break
      case 'y':
        colorX = 0x00ff00
        colorY = 0xffff00 // yellow
        break
      case 'z':
        colorX = 0xff0000
        colorY = 0xffff00 // yellow
        break
      default:
        break
    }
    // Creating crosshairs
    lineXRef.current = createLine(
      new THREE.Vector3(
        crosshairsPositionY.x - volumeWidth / 2,
        crosshairsPositionY.y,
        crosshairsPositionY.z
      ),
      new THREE.Vector3(
        crosshairsPositionY.x + volumeWidth / 2,
        crosshairsPositionY.y,
        crosshairsPositionY.z
      ),
      colorX
    )
    lineYRef.current = createLine(
      new THREE.Vector3(
        crosshairsPositionX.x,
        crosshairsPositionX.y - volumeHeight / 2,
        crosshairsPositionX.z
      ),
      new THREE.Vector3(
        crosshairsPositionX.x,
        crosshairsPositionX.y + volumeHeight / 2,
        crosshairsPositionX.z
      ),
      colorY
    )
    if (crosshairsOn) {
      scene.current.add(lineXRef.current)
      scene.current.add(lineYRef.current)
    }
  }, [crosshairsPositionX, crosshairsPositionY, crosshairsOn])

  useEffect(() => {
    if (!viewBoxRef.current) {
      return
    }

    if (rendererRef.current) {
      updateSize()
    }

    if (containerRef.current) {
      window.addEventListener('resize', updateSize)
    }

    return () => {
      // Clean up the animation loop when component unmounts
      if (containerRef.current) {
        window.removeEventListener('resize', updateSize)
      }
    }
  }, [viewBoxRef])

  useEffect(() => {
    if (!meshRef.current || !brightness || !contrast) return

    meshRef.current.material.color = adjustBrightnessContrast(brightness, contrast)
  }, [brightness, contrast])

  useEffect(() => {
    if (scene.current) {
      removeAllObjects(scene.current, 'circle')
      removeAllObjects(scene.current, 'line')
      removeAllObjects(scene.current, 'measure')
      removeAllObjects(scene.current, 'arc')
    }

    if (containerRef.current) {
      containerRef.current.addEventListener('pointerdown', onPointerClick)
    }

    return () => {
      // Clean up the animation loop when component unmounts
      if (containerRef.current) {
        containerRef.current.removeEventListener('pointerdown', onPointerClick)
      }
    }
  }, [measureConfig])

  useEffect(() => {
    if (scene.current) {
      removeAllObjects(scene.current, 'closeCurveLine')
    }

    if (containerRef.current) {
      containerRef.current.addEventListener('pointerdown', onPointerClick)
      containerRef.current.addEventListener('pointerup', onPointerRelease)
      containerRef.current.addEventListener('mousemove', circleCursor)
    }

    if (segmentConfig.type !== 'None') {
      if (buttonState !== 'Image' && buttonState !== 'None') {
        if (labelRef.current !== null) {
          update2DTexture(
            labelRef.current,
            sliceSegRef.current,
            meshSegRef.current,
            labelColorSwitch
          )
        }
      }
    }

    return () => {
      // Clean up the animation loop when component unmounts
      if (containerRef.current) {
        containerRef.current.removeEventListener('pointerdown', onPointerClick)
        containerRef.current.removeEventListener('pointerup', onPointerRelease)
        containerRef.current.removeEventListener('mousemove', circleCursor)
      }
    }
  }, [currentView, segmenting, Radius, selectedSegment, segmentConfig])

  useEffect(() => {
    if (!mainGUIRef.current) {
      return
    }

    if (slider.current !== -1) {
      const guiFolder = mainGUIRef.current.__folders['Views']

      let slidercontroller = guiFolder.__controllers.find(
        (controller) => controller.property === sliderType
      )
      // console.log("2D guiFolder: ", guiFolder)

      if (slidercontroller) {
        // console.log('slider true')
        slidercontroller.setValue(slider.current)

        if (meshSegRef.current !== null && labelRef.current != null) {
          update2DTexture(
            labelRef.current,
            sliceSegRef.current,
            meshSegRef.current,
            labelColorSwitch
          )
        }
      }
    }
    // console.log('slider.current: ', slider.current)
  }, [slider.current])

  useEffect(() => {
    if (wheel !== -1) {
      setslider({
        ...slider,
        current: wheel,
        max: slider.max
      })
    }
  }, [wheel])

  useEffect(() => {
    if (meshSegRef.current !== null) {
      meshSegRef.current.visible = isSegmentationVisible
    }
  }, [isSegmentationVisible])

  useEffect(() => {
    if (sliceSegRef.current && IsLabelChange && labelRef.current != null) {
      update2DTexture(labelRef.current, sliceSegRef.current, meshSegRef.current, labelColorSwitch)
    }
  }, [IsLabelChange])

  useEffect(() => {
    if (sliceSegRef.current && labelRef.current != null) {
      update2DTexture(labelRef.current, sliceSegRef.current, meshSegRef.current, labelColorSwitch)
    }
  }, [labelColorSwitch])

  useEffect(() => {
    if (isIntensityChange) {
      const existingMesh = scene.current.getObjectByName('mesh_' + axis)
      if (existingMesh) {
        scene.current.remove(existingMesh)
        if (existingMesh.material && existingMesh.material.map) {
          existingMesh.material.map.dispose() // Dispose of texture
        }
        existingMesh.material.dispose() // Dispose of material
        existingMesh.geometry.dispose() // Dispose of geometry
      }

      // Extract the new slice based on current slider position
      const slice = volume2D.extractSlice(axis, slider.current)
      sliceRef.current = slice

      // Configure material properties based on the slice
      slice.mesh.material.transparent = true
      slice.mesh.material.opacity = 0.8
      if (axis === 'z' || axis === 'x') {
        slice.mesh.material.map.flipY = false
      }

      // Create the new mesh with updated material and geometry
      const mesh = new THREE.Mesh(geometryRef.current, slice.mesh.material)
      mesh.name = 'mesh_' + axis

      // Apply brightness and contrast adjustments
      mesh.material.color = adjustBrightnessContrast(brightness, contrast)

      // Adjust mesh properties based on axis
      if (axis === 'x') {
        mesh.scale.x = -1 // Flip left to right
      }

      // Add the new mesh to the scene
      meshRef.current = mesh
      scene.current.add(mesh)

      // Optionally, update the renderer if the scene needs to be immediately visible
      animate()

      SetIsIntensityChange(false)
    }
  }, [isIntensityChange])

  useEffect(() => {
    if (isRestore) {
      labelRef.current.data = new Float32Array(predictedData)

      update2DTexture(labelRef.current, sliceSegRef.current, meshSegRef.current, labelColorSwitch)

      setIsRestore(false)
    }
  }, [isRestore])

  const onPointerClick = useCallback(
    (event) => {
      boundOnPointerClick(
        event,
        view,
        currentView,
        setCurrentView,
        rendererRef.current,
        scene.current,
        cameraRef.current,
        volume,
        geometryRef.current,
        measureConfig,
        segmentConfig,
        setSegmenting,
        measureDots,
        curvePointList,
        measureDots3D,
        slider,
        setClickedPoint
      )
    },
    [currentView, measureConfig, segmentConfig, mousePoints, setSegmenting]
  )

  const circleCursor = useCallback(
    (event) => {
      boundCircleCursor(
        event,
        view,
        segmentConfig,
        segmenting,
        scene.current,
        cameraRef.current,
        rendererRef.current,
        Radius,
        volume,
        geometryRef.current,
        mousePoints,
        curvePointList
      )
    },
    [segmentConfig, segmenting, Radius, mousePoints, selectedSegment]
  )

  const onPointerRelease = useCallback(
    (event) => {
      boundOnPointerRelease(
        rendererRef.current,
        scene.current,
        cameraRef.current,
        segmentConfig,
        setSegmenting,
        mousePoints,
        volume,
        labelRef.current,
        Radius,
        sliceRef.current,
        sliceSegRef.current,
        selectedSegment,
        setLabel,
        view,
        geometryRef.current,
        curvePointList,
        event,
        labelThresholdRange,
        setIsSegmentToolAffected,
        clickedPoint
      )
    },
    [segmentConfig, setSegmenting, mousePoints, Radius, clickedPoint, selectedSegment]
  )

  return <div ref={containerRef} />
}

export default RenderingSagittalView
