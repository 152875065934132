/* eslint-disable react/prop-types */
import React, { useContext, useRef } from 'react'
import { Box, Typography } from '@mui/material'
import OpenWithIcon from '@mui/icons-material/OpenWith'
import Draggable from 'react-draggable'
import { UserContext } from '../../../App'
import * as THREE from 'three'

const PositionInfoBox = () => {
  const draggableRef = useRef(null)

  const { objectPositionInfo } = useContext(UserContext)
  var pi = Math.PI
  var translation, rotation

  if (
    objectPositionInfo.trans != null &&
    objectPositionInfo.trans != undefined &&
    objectPositionInfo.rotation != null &&
    objectPositionInfo.rotation != undefined
  ) {
    // console.log("objectPositionInfo: ", objectPositionInfo)
    translation = objectPositionInfo.trans
    rotation = new THREE.Euler().setFromQuaternion(objectPositionInfo.rotation, 'XYZ')
  } else {
    translation = new THREE.Vector3()
    rotation = new THREE.Euler()
  }

  return (
    <Draggable nodeRef={draggableRef} handle=".drag-icon">
      <Box
        ref={draggableRef}
        sx={{
          position: 'absolute',
          flexDirection: 'column',
          top: '65%',
          left: '10%',
          transform: 'translateX(-50%)',
          zIndex: 10000,
          alignItems: 'flex-start',
          backgroundColor: '#303030', // Box background color
          padding: '16px 0px',
          borderRadius: '8px',
          width: '310px',
          maxWidth: '90%',
          color: '#fff',
          marginTop: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 1,
            backgroundColor: '#303030', // Matching header color with box color
            padding: '0 8px', // Reduced padding in the header
            borderRadius: '2px 2px 0 0' // Rounded corners on top only
          }}
        >
          <div>
            <OpenWithIcon
              className="drag-icon"
              sx={{
                color: '#FFFFFF',
                cursor: 'move',
                backgroundColor: '',
                borderRadius: '50%',
                fontSize: '25px',
                zIndex: 9999
              }}
            />
          </div>
          <Typography variant="h6" component="span" sx={{ flexGrow: 1 }}>
            Rotation and Translation Status
          </Typography>
        </Box>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            marginLeft: '20px',
            marginRight: '20px'
          }}
        >
          <div>
            <Typography variant="body2">
              Translation in X:
              <span
                style={{
                  float: 'right',
                  color: objectPositionInfo.trans == 'DEFINED' ? 'green' : 'white'
                }}
              >
                {Math.round(translation.x * 100) / 100 + ' mm'}
              </span>
            </Typography>
          </div>
          <div>
            <Typography variant="body2">
              Translation in Y:
              <span
                style={{
                  float: 'right',
                  color: objectPositionInfo.trans == 'DEFINED' ? 'green' : 'white'
                }}
              >
                {Math.round(translation.y * 100) / 100 + ' mm'}
              </span>
            </Typography>
          </div>
          <div>
            <Typography variant="body2">
              Translation in Z:
              <span
                style={{
                  float: 'right',
                  color: objectPositionInfo.trans == 'DEFINED' ? 'green' : 'white'
                }}
              >
                {Math.round(translation.z * 100) / 100 + ' mm'}
              </span>
            </Typography>
          </div>
          <div>
            <Typography variant="body2">
              Rotation around X:
              <span
                style={{
                  float: 'right',
                  color: objectPositionInfo.trans == 'DEFINED' ? 'green' : 'white'
                }}
              >
                {Math.round(rotation.x * 100 * (180 / pi)) / 100 + ' °'}
              </span>
            </Typography>
          </div>
          <div>
            <Typography variant="body2">
              Rotation around Y:
              <span
                style={{
                  float: 'right',
                  color: objectPositionInfo.trans == 'DEFINED' ? 'green' : 'white'
                }}
              >
                {Math.round(rotation.y * 100 * (180 / pi)) / 100 + ' °'}
              </span>
            </Typography>
          </div>
          <div>
            <Typography variant="body2">
              Rotation around Z:
              <span
                style={{
                  float: 'right',
                  color: objectPositionInfo.trans == 'DEFINED' ? 'green' : 'white'
                }}
              >
                {Math.round(rotation.z * 100 * (180 / pi)) / 100 + ' °'}
              </span>
            </Typography>
          </div>
        </div>
      </Box>
    </Draggable>
  )
}

export default PositionInfoBox
