import {
  segCircle,
  eraseSegment,
  addSegment,
  calculateAndAdjustMousePoints
} from './SegmentToolHandler'
import { measure, measure3D } from './MeasureHandler'
import * as THREE from 'three'
import {
  createClosedShape,
  eraseClosedShape,
  savePoints,
  savePointsOnMouseMove,
  calculateStepsize
} from './ScissorToolHandler'

function animate(renderer, scene, camera) {
  renderer.render(scene, camera)
}

export const boundCircleCursor = (
  event,
  viewId,
  segmentConfig,
  segmenting,
  scene,
  camera,
  renderer,
  Radius,
  volume,
  geometry,
  mousePoints,
  curvePointList
) => {
  if (
    segmentConfig.type?.includes('Segmentation') ||
    segmentConfig.type === 'Erase_' ||
    segmentConfig.type === 'Erase'
  ) {
    segCircle(event, viewId, segmenting, scene, camera, Radius, volume, geometry, mousePoints)
    animate(renderer, scene, camera)
  } else if (segmentConfig.type === 'Scissor Erase' || segmentConfig.type == 'Scissor Seg') {
    savePointsOnMouseMove(event, camera, scene, curvePointList, segmenting)
    // createClosedShape(curvePointList, scene, camera, label, sliceSeg, setLabel, viewId, volume, geometry, slice, selectedSegment, segmentConfig);
    // eraseClosedShape(scene);
  }
}

export const boundHandleMouseWheel = (event, viewId, volume, slice, setWheel) => {
  if (viewId === 'View1') {
    setWheel(Math.min(volume.RASDimensions[1] - 1, Math.max(0, slice.index - event.deltaY * 0.01)))
  } else if (viewId === 'View2') {
    setWheel(Math.min(volume.RASDimensions[2] - 1, Math.max(0, slice.index - event.deltaY * 0.01)))
  } else if (viewId === 'View3') {
    setWheel(Math.min(volume.RASDimensions[0] - 1, Math.max(0, slice.index - event.deltaY * 0.01)))
  }
}

export const boundOnPointerRelease = (
  renderer,
  scene,
  camera,
  segmentConfig,
  setSegmenting,
  mousePoints,
  volume,
  label,
  Radius,
  slice,
  sliceSeg,
  selectedSegment,
  setLabel,
  viewId,
  geometry,
  curvePointList,
  event,
  labelThresholdRange,
  setIsSegmentToolAffected,
  clickedPoint
) => {
  //dummy function / demo for visualization and checking purposes
  // viewId;
  // geometry;
  // curvePointList;

  if (segmentConfig.type.includes('_')) {
    const newType = segmentConfig.type.replace(/_/g, '')
    segmentConfig.type = newType
  }

  if (segmentConfig.type !== 'None') {
    //turning off segmentation on release
    setSegmenting(false)
  }

  if (segmentConfig.type == 'Erase' || segmentConfig.type == 'Erase_') {
    eraseSegment(
      mousePoints,
      label,
      Radius,
      sliceSeg,
      selectedSegment,
      setLabel,
      setIsSegmentToolAffected,
      clickedPoint,
      volume,
      viewId,
      geometry
    )
  } else if (segmentConfig.type?.includes('Segmentation')) {
    addSegment(
      mousePoints,
      Radius,
      slice,
      sliceSeg,
      volume,
      label,
      selectedSegment,
      setLabel,
      labelThresholdRange,
      setIsSegmentToolAffected,
      clickedPoint,
      viewId,
      geometry
    )
  } else if (segmentConfig.type === 'Scissor Erase' || segmentConfig.type == 'Scissor Seg') {
    var deltaX = calculateStepsize(event)
    createClosedShape(
      curvePointList,
      scene,
      camera,
      label,
      sliceSeg,
      setLabel,
      viewId,
      volume,
      geometry,
      slice,
      selectedSegment,
      segmentConfig,
      deltaX,
      labelThresholdRange,
      setIsSegmentToolAffected
    )
    eraseClosedShape(scene)
  }

  mousePoints = []
  animate(renderer, scene, camera)
}

export const boundOnPointerClick = (
  event,
  viewId,
  currentView,
  setCurrentView,
  renderer,
  scene,
  camera,
  volume,
  geometry,
  measureConfig,
  segmentConfig,
  setSegmenting,
  measureDots,
  curvePointList,
  _measureDots,
  slider_value,
  setClickedPoint
) => {
  //dummy function / demo for visualization and checking purposes
  // getCoordinatesIn3DView(event, scene, camera);

  if (currentView !== viewId.toLowerCase()) {
    if (segmentConfig.type !== 'None') {
      setCurrentView(viewId.toLowerCase()) //change viewID to lowercase in refactoring
    }
  } else {
    if (measureConfig.type == 'Measure' || measureConfig.type == 'Angle') {
      measure(event, viewId, scene, camera, volume, geometry, measureConfig, measureDots)
      measure3D(
        event,
        viewId,
        scene,
        camera,
        volume,
        geometry,
        measureConfig,
        _measureDots,
        slider_value,
        geometry
      )
      animate(renderer, scene, camera)
    } else {
      if (segmentConfig.type !== 'None') {
        if (segmentConfig.type === 'Scissor Points') {
          savePoints(event, camera, scene, curvePointList, viewId, volume, geometry)
        } else {
          console.log('click....')
          setSegmenting(true)
          const adjustedPoints = calculateAndAdjustMousePoints(
            event,
            viewId,
            volume,
            geometry,
            scene,
            camera,
            null,
            false
          )
          setClickedPoint(adjustedPoints)

          animate(renderer, scene, camera)
        }
      }
    }
  }
}

export function getCoordinatesIn3DView(event, scene, camera) {
  // Get the bounding rectangle of the renderer element
  //const rect = renderer.domElement.getBoundingClientRect();
  //const cameraRotation = camera.rotation;
  let bbox = event.currentTarget.getBoundingClientRect()
  let pointer = new THREE.Vector3()
  let raycaster = new THREE.Raycaster()

  // Calculate the normalized device coordinates (NDC) based on the mouse event
  // const mouse = {
  //   x: ((event.clientX - rect.left) / rect.width) * 2 - 1,
  //   y: -((event.clientY - rect.top) / rect.height) * 2 + 1,
  // };

  pointer.set(
    ((event.clientX - bbox.x) / event.currentTarget.clientWidth) * 2 - 1,
    -((event.clientY - bbox.y) / event.currentTarget.clientHeight) * 2 + 1,
    0
  )

  // Create a raycaster from the camera position and direction
  raycaster.setFromCamera(pointer, camera)

  // Intersect the ray with the scene to get the intersection point
  const intersects = raycaster.intersectObjects(scene.children)
  //console.log("The camera orientation is: (", cameraRotation.x, ", ",cameraRotation.y, ", ", cameraRotation.z, ")" )

  if (intersects.length > 0) {
    // Return the coordinates of the first intersection point
    console.log('Intersection at ', intersects[0].point)

    console.log('deltaX: ', 2 / event.currentTarget.clientWidth)
    console.log('Intersection at ', intersects[0].point)
  } else {
    console.log('No intersection')
  }
}
